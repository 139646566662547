import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import * as ApiConstants from '../common/ApiConstants';
import './../css/TimeLine.css';

export class TplaNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noteAdded: false,
      notes: []
    };
    this.getNotes();
  }

  getNotes = () => {
    const url =
      ApiConstants.URL_TPLA + `${this.props.tplaId}/${ApiConstants.TPLA_NOTES}`;
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      }
    })
      .then(response => {
        this.setState({
          notes: response.data.data
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            const message = 'Error fetching tpla notes';
            alert(message);
          }
        } else {
          alert('Error fetching tpla activities');
        }
      });
  };

  addNote = () => {
    let value = document.getElementById('notes-textarea').value.trim();
    if (value.length === 0) {
      alert("Can't add empty note");
      return;
    }
    this.submitNote(value);
  };

  submitNote(value) {
    // Add to first place of all notes and send to server
    let tplaId = this.props.tplaId;
    let url = ApiConstants.URL_TPLA + `${tplaId}/${ApiConstants.TPLA_NOTES}/`;
    let body = {
      tpla: tplaId,
      note: value,
      action: 'added' // Added note
    };

    axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      },
      data: body
    })
      .then(response => {
        this.setState({
          noteAdded: true,
          notes: [response.data.data, ...this.state.notes]
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            const message = 'error';
            alert(message);
          }
        } else {
          alert('Notification could not be sent');
        }
        // this.props.sendingNotifications(false);
      });
  }

  render() {
    return (
      <div className="notes-main">
        <div className="notes-inner">
          <h3>Notes</h3>
          <div className="notes-input">
            <textarea rows="4" id="notes-textarea"></textarea>
            <button className="save_notes" onClick={this.addNote}>
              Add Note
            </button>
          </div>
          <div className="notes-timeline">
            <label>All Notes</label>
            <div className="notes-list">
              {this.state.notes.map(note => (
                <TplaNotesItem
                  key={note.id}
                  by_user={note.by_user}
                  note={note.note}
                  created_at={note.created_at}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class TplaNotesItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: localStorage.getItem('current_user'),
      current_time: new Date().toLocaleString()
    };
  }
  formatDate = inputDate => {
    const created_at = new Date(inputDate).toString().substring(4, 21);
    return created_at;
  };

  render() {
    return (
      // <div className={"notes-data" + this.props.highlight ? 'highlight': ''}>
      <div className="notes-data">
        <div className="notes-content">{this.props.note}</div>
        <div className="notes-meta">
          <i>{<FontAwesomeIcon icon={faUser} size="1x" />}</i>
          <span>{this.props.by_user} </span> on
          <time>{this.formatDate(this.props.created_at)}</time>
        </div>
      </div>
    );
  }
}
