import { createStore, combineReducers } from "redux";
import {
  setAuthStatusReducer,
  setLoadingStatusReducer,
  setRowsAndCountOfTplasReducer,
  setTplaFiltersReducer,
  setTplaDetailsReducer,
  setNotificationDataReducer,
  sendingNotificationsReducer,
  updatePreviewDocsReducer,
  setTabChangeStatusReducer,
  setCurrentTabIndexReducer,
  setTimeLineDataReducer,
  setReminderTemplatesReducer,
  setTemplateSelectionReducer
} from "./reducers/Reducers";

const rootReducer = combineReducers({
  //login
  authStatus: setAuthStatusReducer,
  //tpla
  loadingStatus: setLoadingStatusReducer,
  rowsAndCount: setRowsAndCountOfTplasReducer,
  tplaFilters: setTplaFiltersReducer,
  //tpla detail
  tplaData: setTplaDetailsReducer,
  notificationDetails: setNotificationDataReducer,
  notificationSendStatus: sendingNotificationsReducer,
  newDocs: updatePreviewDocsReducer,
  tabChangeStatus: setTabChangeStatusReducer,
  tabsCurIndex: setCurrentTabIndexReducer,
  timeLine: setTimeLineDataReducer,
  reminderTemplates: setReminderTemplatesReducer,
  selectedOption: setTemplateSelectionReducer
});

export default createStore(rootReducer);
