import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRupeeSign,
  faHandHoldingUsd,
  faWallet,
  faMoneyBillWave
} from '@fortawesome/free-solid-svg-icons';
import * as ApiConstants from '../common/ApiConstants';

function fetchData(method, url, body) {
  // create a common request handler.
  return axios({
    method: method,
    url: url,
    headers: {
      Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
    },
    data: body
  })
    .then(response => {})
    .catch(error => {
      console.log(error);
      if (error.response) {
        if (error.response.status === 401) {
          alert('Entry is unauthorized');
          localStorage.clear();
          this.props.history.push('/login');
        } else if (error.response.status === 403) alert('Entry is prohibited');
        else {
          const message = 'Error fetching Insights';
          alert(message);
        }
      } else {
        alert('Error fetching Insights');
      }
    });
}

function formatKeyName(key) {
  key = String(key);
  return key
    .toLowerCase()
    .split('_')
    .map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

function formatValue(value) {
  value = String(value);
  {
    value.includes(':') === true && value.includes('{') === true // check if values is a json or python dict
      ? (value = <code className="value">{value}</code>)
      : (value = <span className="value">{value}</span>);
  }
  return value;
}

class InsightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // insightsData: this.props.insightsData,
      bankingData: this.props.banking,
      loanData: this.props.loan,
      spendData: this.props.spend,
      walletData: this.props.wallet
    };
  }

  renderCommonData(data) {
    Object.entries(data).map(([key, value], index) =>
      ['period_month', 'period_from', 'period_to'].includes(key) ? (
        <p key={index}>
          <span>{key}</span>: <span>{value}</span>
        </p>
      ) : null
    );
  }

  render() {
    return (
      <div className="insights-tabbed-data">
        <Tabs>
          <TabList>
            <Tab key="banking">
              <i>{<FontAwesomeIcon icon={faRupeeSign} size="1x" />}</i>Banking
            </Tab>
            <Tab key="loan">
              <i>{<FontAwesomeIcon icon={faHandHoldingUsd} size="1x" />}</i>Loan
            </Tab>
            <Tab key="spend">
              <i>{<FontAwesomeIcon icon={faMoneyBillWave} size="1x" />}</i>Spend
            </Tab>
            <Tab key="wallet">
              <i>{<FontAwesomeIcon icon={faWallet} size="1x" />}</i>Wallet
            </Tab>
          </TabList>
          {
            <div>
              <div className="common_data">
                {this.props.banking.data.message !== undefined
                  ? null // message will shown inside the panel
                  : Object.entries(this.props.banking.data).map(
                      ([key, value], index) =>
                        ['period_month', 'period_from', 'period_to'].includes(
                          key
                        ) ? (
                          <p key={'key-' + index} className="common">
                            <span>{formatKeyName(key)}: </span>
                            <span>{String(value).slice(0, 10)}</span>
                          </p>
                        ) : null // don't show anything if key is other
                    )}
              </div>
              <TabPanel>
                <div className="insights-data">
                  <table>
                    <tbody>
                      {this.props.banking.data.message !== undefined ? (
                        <tr>
                          <td className="message">
                            {this.props.banking.data.message}
                          </td>
                        </tr>
                      ) : (
                        Object.entries(this.props.banking.data).map(
                          ([key, value], index) =>
                            [
                              'period_month',
                              'period_from',
                              'period_to'
                            ].includes(key) ? null : (
                              <tr key={'key-' + index}>
                                <td className="key">
                                  <span className="key">
                                    {formatKeyName(key)}:{' '}
                                  </span>
                                </td>
                                <td>{formatValue(value)}</td>
                              </tr>
                            )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="insights-data">
                  <table>
                    <tbody>
                      {this.props.loan.data.message !== undefined ? (
                        <tr>
                          <td className="message">
                            {this.props.loan.data.message}
                          </td>
                        </tr>
                      ) : (
                        Object.entries(this.props.loan.data).map(
                          ([key, value], index) => (
                            <tr key={'key-' + index}>
                              <td className="key">
                                <span className="key">
                                  {formatKeyName(key)}:{' '}
                                </span>
                              </td>
                              <td>{formatValue(value)}</td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="insights-data">
                  <table>
                    <tbody>
                      {this.props.spend.data.message !== undefined ? (
                        <tr>
                          <td className="message">
                            {this.props.spend.data.message}
                          </td>
                        </tr>
                      ) : (
                        Object.entries(this.props.spend.data).map(
                          ([key, value], index) => (
                            <tr key={'key-' + index}>
                              <td className="key">
                                <span className="key">
                                  {formatKeyName(key)}:{' '}
                                </span>
                              </td>
                              <td>{formatValue(value)}</td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="insights-data">
                  <table>
                    <tbody>
                      {this.props.wallet.data.message !== undefined ? (
                        <tr>
                          <td className="message">
                            {this.props.wallet.data.message}
                          </td>
                        </tr>
                      ) : (
                        Object.entries(this.props.wallet.data).map(
                          ([key, value], index) => (
                            <tr key={'key-' + index}>
                              <td className="key">
                                <span className="key">
                                  {formatKeyName(key)}:{' '}
                                </span>
                              </td>
                              <td>{formatValue(value)}</td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </div>
          }
        </Tabs>
      </div>
    );
  }
}

export class UserInsights extends Component {
  constructor(props) {
    super();
    this.state = {
      insightsFetched: false,
      bankingInsightsData: null,
      loanInsightsData: null,
      spendInsightsData: null,
      walletInsightsData: null,
      allInsightsData: null,
      message: null,
      currentTplaId: props.tplaId,
      currentUser: props.user
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      currentTplaId: props.tplaId,
      currentUser: props.user
    };
  }

  componentDidMount() {
    this.getBankingInsight();
  }

  componentDidUpdate(nextProps, nextState) {
    // this.state.currentTplaId !== nextProps.tplaId
    if (nextProps.tplaId !== this.state.currentTplaId) {
      this.getBankingInsight();
    }
  }

  getBankingInsight = () => {
    const url = ApiConstants.USER_INSIGHT_URL;
    // return fetchData('post', url, {'user_id': this.props.user.id})
    axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      },
      data: { user_id: this.props.user.id }
    })
      .then(response => {
        this.setState({
          insightsFetched: true,
          allInsightsData: response.data.data,
          bankingInsightsData: response.data.data.banking,
          loanInsightsData: response.data.data.loan,
          spendInsightsData: response.data.data.spend,
          walletInsightsData: response.data.data.wallet,
          message: response.data.message
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else if (error.response.status === 429)
            alert(error.response.data.detail);
          else {
            alert(error.response.data.detail);
          }
        } else {
          alert('Error fetching Insights');
        }
      });
  };

  render() {
    return (
      <div className="insights-main">
        <div className="insights-inner">
          <h4>Insights</h4>
          {this.state.insightsFetched ? (
            [
              this.state.bankingInsightsData.message ? (
                <InsightPanel>
                  <p key="banking">{this.state.bankingInsightsData.message}</p>
                </InsightPanel>
              ) : (
                <InsightPanel
                  key="prop"
                  insightsData={this.state.allInsightsData}
                  banking={this.state.bankingInsightsData}
                  loan={this.state.loanInsightsData}
                  spend={this.state.spendInsightsData}
                  wallet={this.state.walletInsightsData}
                />
              )
            ]
          ) : (
            <p>Loading Insights...</p>
          )}
        </div>
      </div>
    );
  }
}
