import {
  LOGIN,
  LOGOUT,
  SET_LOADING_STATUS,
  TPLA_LIST_UPDATE,
  TPLA_FILTERS_UPDATE,
  TPLA_DETAILS_UPDATE,
  SET_NOTIFICATION_DATA,
  SENDING_NOTIFICATIONS,
  UPDATING_PREVIEW_DOCS,
  SET_TAB_CHANGE_STATUS,
  SET_CURRENT_TAB_INDEX,
  SET_TIMELINE_DATA,
  SET_REMINDER_TEMPLATES,
  SET_TEMPLATE_SELECTION
} from "../actions/Actions";

// Reducers responsible for setting state in Login.js

// updates username and auth status
export function setAuthStatusReducer(
  state = { username: "", authenticated: false },
  action
) {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        username: action.username,
        authenticated: action.authenticated
      });
    case LOGOUT:
      return Object.assign({}, state, {
        username: "",
        authenticated: false
      });
    default:
      return state;
  }
}

// Reducers responsible for setting state in Tpla.js

// updates loading status
export function setLoadingStatusReducer(state = { loading: false }, action) {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return Object.assign({}, state, {
        loading: action.loading
      });
    default:
      return state;
  }
}

// updates tpla list and total count of tpla's
export function setRowsAndCountOfTplasReducer(
  state = { rows: [], total: 5000 },
  action
) {
  switch (action.type) {
    case TPLA_LIST_UPDATE:
      return Object.assign({}, state, {
        rows: action.tplaListData.rows,
        total: action.tplaListData.totalCount
      });
    default:
      return state;
  }
}

// updates filters in tpla
export function setTplaFiltersReducer(state = { filters: {} }, action) {
  switch (action.type) {
    case TPLA_FILTERS_UPDATE:
      return Object.assign({}, state, {
        filters: action.filters
      });
    default:
      return state;
  }
}

// Reducers responsible for setting state in TplaDetail.js

// updates tpla details
export function setTplaDetailsReducer(state = { tplaDetails: {} }, action) {
  switch (action.type) {
    case TPLA_DETAILS_UPDATE:
      return Object.assign({}, state, {
        tplaDetails: action.tplaData
      });
    default:
      return state;
  }
}

// updates notification data
export function setNotificationDataReducer(
  state = { notificationData: [] },
  action
) {
  switch (action.type) {
    case SET_NOTIFICATION_DATA:
      return Object.assign({}, state, {
        notificationData: action.notificationData
      });
    default:
      return state;
  }
}

// sets notification sending status
export function sendingNotificationsReducer(
  state = { notificationBeingSent: false },
  action
) {
  switch (action.type) {
    case SENDING_NOTIFICATIONS:
      return Object.assign({}, state, {
        notificationBeingSent: action.value
      });
    default:
      return state;
  }
}

// updates preview docs
export function updatePreviewDocsReducer(state = { docs: [] }, action) {
  switch (action.type) {
    case UPDATING_PREVIEW_DOCS:
      return Object.assign({}, state, {
        docs: action.docs
      });
    default:
      return state;
  }
}

// updates status of whether tabs were changed
export function setTabChangeStatusReducer(
  state = { tabChange: false },
  action
) {
  switch (action.type) {
    case SET_TAB_CHANGE_STATUS:
      return Object.assign({}, state, {
        tabChange: action.value
      });
    default:
      return state;
  }
}

// sets current tab index
export function setCurrentTabIndexReducer(
  state = { tplaDetailsTabIndex: 0 },
  action
) {
  switch (action.type) {
    case SET_CURRENT_TAB_INDEX:
      return Object.assign({}, state, {
        tplaDetailsTabIndex: action.index
      });
    default:
      return state;
  }
}

// updates timeline data
export function setTimeLineDataReducer(state = { timeLineData: [] }, action) {
  switch (action.type) {
    case SET_TIMELINE_DATA:
      return Object.assign({}, state, {
        timeLineData: action.timeLineData
      });
    default:
      return state;
  }
}

// updates reminder templates
export function setReminderTemplatesReducer(
  state = { reminderTemplateOptions: [] },
  action
) {
  switch (action.type) {
    case SET_REMINDER_TEMPLATES:
      return Object.assign({}, state, {
        reminderTemplateOptions: action.reminderTemplates
      });
    default:
      return state;
  }
}

// update selected template option
export function setTemplateSelectionReducer(
  state = { templateSelection: "" },
  action
) {
  switch (action.type) {
    case SET_TEMPLATE_SELECTION:
      return Object.assign({}, state, {
        templateSelection: action.selectedOption
      });
    default:
      return state;
  }
}
