import React, { Component } from "react";
import Chart from "chart.js";
import { MdFullscreen } from "react-icons/md";
import { IconContext } from "react-icons";
import CustomButton from "./Button";
import "../css/Chart.css";

export class HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets[0].data = this.props.values;
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "horizontalBar",
      options: {
        maintainAspectRatio: false,
        elements: {
          rectangle: {
            borderWidth: 2
          }
        },
        responsive: true,
        legend: {
          position: "right"
        },
        title: {
          display: true,
          text: this.props.title
        },
        scales: {
          xAxes: [
            {
              ticks: {
                min: this.props.options.min,
                max: this.props.options.max,
                stepSize: this.props.options.stepSize
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data.map(dataset => ({
          label: dataset.title,
          data: dataset.values,
          backgroundColor: dataset.backgroundColor,
          hoverBackgroundColor: dataset.hoverBackgroundColor
        }))
      }
    });
  }

  handleFullScreen = () => {
    let id = this.props.id;
    let elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  render() {
    return (
      <>
        <CustomButton
          onClick={this.handleFullScreen}
          title={
            <IconContext.Provider
              value={{ color: "black", className: "react-icons" }}
            >
              <MdFullscreen size="30" />
            </IconContext.Provider>
          }
          type="default"
        />
        <canvas id={this.props.id} ref={this.canvasRef} />
      </>
    );
  }
}

export class VerticalBarChart extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.labels;
    this.myChart.data.datasets[0].data = this.props.values;
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "bar",
      options: {
        maintainAspectRatio: false,
        elements: {
          rectangle: {
            borderWidth: 2
          }
        },
        responsive: true,
        legend: {
          position: "right"
        },
        title: {
          display: true,
          text: this.props.title
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: this.props.options.min,
                max: this.props.options.max,
                stepSize: this.props.options.stepSize
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data.map(dataset => ({
          label: dataset.title,
          data: dataset.values,
          backgroundColor: dataset.backgroundColor,
          hoverBackgroundColor: dataset.hoverBackgroundColor
        }))
      }
    });
  }

  handleFullScreen = () => {
    let id = this.props.id;
    let elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  render() {
    return (
      <>
        <CustomButton
          onClick={this.handleFullScreen}
          title={
            <IconContext.Provider
              value={{ color: "black", className: "react-icons" }}
            >
              <MdFullscreen size="30" />
            </IconContext.Provider>
          }
          type="default"
        />
        <canvas id={this.props.id} ref={this.canvasRef} />
      </>
    );
  }
}
