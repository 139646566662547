import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as ApiConstants from '../common/ApiConstants';
import { withRouter } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { TimeLineContainer } from '../components/TimeLineContainer';
import { TplaNotes } from '../components/TplaNotes';
import { UserInsights } from '../components/UserInsights';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import {
  Editable,
  DocsPreview,
  HeaderField,
  OptionField
} from '../components/TplaFields';
import DocPreview from '../components/Preview';
import CustomButton from '../components/Button';
import { IconContext } from 'react-icons';
import { MdClose } from 'react-icons/md';
import { CheckBoxFilter } from '../components/Filters';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setTplaDetails,
  setNotificationData,
  sendingNotifications,
  updatePreviewDocs,
  setTabChangeStatus,
  setCurrentTabIndex,
  setTimeLineData,
  setReminderTemplates,
  setTemplateSelection
} from './../actions/Actions';
import '../css/TplaDetail.css';

const mapStateToProps = state => {
  return {
    tplaDetails: state.tplaData.tplaDetails,
    notificationData: state.notificationDetails.notificationData,
    notificationBeingSent: state.notificationSendStatus.notificationBeingSent,
    docs: state.newDocs.docs,
    tabChange: state.tabChangeStatus.tabChange,
    tplaDetailsTabIndex: state.tabsCurIndex.tplaDetailsTabIndex,
    timeLineData: state.timeLine.timeLineData,
    reminderTemplateOptions: state.reminderTemplates.reminderTemplateOptions,
    templateSelection: state.selectedOption.templateSelection
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTplaDetails: tplaData => {
      return Promise.resolve(dispatch(setTplaDetails(tplaData)));
    },
    setNotificationData: notificationData =>
      dispatch(setNotificationData(notificationData)),
    sendingNotifications: value => {
      return Promise.resolve(dispatch(sendingNotifications(value)));
    },
    updatePreviewDocs: docs => dispatch(updatePreviewDocs(docs)),
    setTabChangeStatus: value => dispatch(setTabChangeStatus(value)),
    setCurrentTabIndex: index => dispatch(setCurrentTabIndex(index)),
    setTimeLineData: timeLineData => {
      return Promise.resolve(dispatch(setTimeLineData(timeLineData)));
    },
    setReminderTemplates: reminderTemplateOptions =>
      dispatch(setReminderTemplates(reminderTemplateOptions)),
    setTemplateSelection: templateSelection =>
      dispatch(setTemplateSelection(templateSelection))
  };
};

function titleCase(str) {
  var splitStr = str.toLowerCase().split('_');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

class TplaDetail extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.originalTplaDetails = {};
    this.state = {
      isBasicEditable: true,
      isLoanDetailsEditable: true,
      isProfessionalDetailsEditable: true,
      isAddressEditable: true,
      tplaUser: null,
      currentTplaId: null,
      pushedToHistory: false
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  headerStyle(column, colIndex) {
    let minWidth = '0px';
    if (colIndex === 5) minWidth = '150px';
    else if (colIndex !== 0) minWidth = '90px';

    return {
      backgroundColor: '#c8e6c9',
      minWidth: minWidth,
      textAlign: 'center'
    };
  }

  serialize = tplaDetails => {
    let tplaFields = tplaDetails.data.tpla_fields;
    let tplaFieldsFormatted = {};
    for (let tplaField of tplaFields) {
      tplaFieldsFormatted[tplaField['key']] = tplaField;
    }
    tplaDetails.data.tpla_fields = tplaFieldsFormatted;
    return tplaDetails;
  };

  handleChange = (name, value) => {
    let tplaDetailsCopy = this.props.tplaDetails;
    tplaDetailsCopy['data']['tpla_fields'][name]['value'] = value;
    this.props.setTplaDetails(tplaDetailsCopy).then();
  };

  handleKeyDown(event) {
    let tplaId = this.props.tplaDetails.data.id;
    let action;
    if (event.keyCode === 37) {
      // left arrow
      action = 'next';
    } else if (event.keyCode === 39) {
      // right arrow
      action = 'previous';
    } else {
      return;
    }
    let url = ApiConstants.URL_TPLA + tplaId + `/?action=${action}`;
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      }
    })
      .then(response => {
        this.setState(
          {
            currentTplaId: response.data.data.id,
            pushedToHistory: true
          },
          () => {
            this.fetchTplaDetails(response.data.data.id, true);
            // this.context.router.history.push( `/tpla/${response.data.data.id}`)
          }
        );
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else if (error.response.status === 404) {
            alert('No record found');
          } else {
            alert('Error fetching TPLA details');
          }
        } else {
          alert('Error fetching TPLA details');
        }
      });
  }

  handleTemplateSelection = (name, value) => {
    this.props.setNotificationData([]);
    this.props.setTemplateSelection(value);
  };

  fetchTplaDetails = (tpla_id = null, pushHistory = false) => {
    let tplaId;
    if (tpla_id !== null) {
      tplaId = tpla_id;
    } else {
      tplaId = this.props.match.params.id;
    }
    console.log(tplaId);

    let url = ApiConstants.URL_TPLA + tplaId + '/';

    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      }
    })
      .then(response => {
        this.originalTplaDetails = this.serialize(response.data);
        let tplaDetails = cloneDeep(this.originalTplaDetails);
        this.props.setTplaDetails(tplaDetails).then();
        this.setState(
          {
            tplaUser: response.data.data.user
          },
          () => {
            if (tpla_id !== null && pushHistory === true) {
              this.context.router.history.push(
                `/tpla/${response.data.data.id}`
              );
              if (this.state.pushedToHistory === false) {
                // this.setState({
                //   pushedToHistory: true
                // }, () => {
                //   this.context.router.history.replaceState(null, null,
                //     `/tpla/${response.data.data.id}`
                //   );
                // })
              }
            }
          }
        );
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error fetching TPLA details');
          }
        } else {
          alert('Error fetching TPLA details');
        }
      });

    url = ApiConstants.URL_GET_REMINDER_TEMPLATES;

    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      }
    })
      .then(response => {
        this.props.setReminderTemplates(response.data.data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error fetching notification templates');
          }
        } else {
          alert('Error fetching notification templates');
        }
      });
  };

  updateTplaDetails = tplaFields => {
    const url = ApiConstants.URL_TPLA + this.props.match.params.id + '/';
    let updatedTplaFields = {};
    let originalTplaDetails = Object.entries(
      this.originalTplaDetails.data.tpla_fields
    );
    let updatedTplaDetails = Object.entries(
      this.props.tplaDetails.data.tpla_fields
    );

    let index = 0;
    for (const [tplaField, doc] of originalTplaDetails) {
      if (tplaFields.includes(tplaField)) {
        if (doc.value !== updatedTplaDetails[index][1].value) {
          updatedTplaFields[tplaField] = updatedTplaDetails[index][1].value;
        }
      }
      index = index + 1;
    }

    axios({
      method: 'put',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN),
        'Content-Type': 'application/json'
      },
      data: updatedTplaFields
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error updating TPLA details');
          }
        } else {
          alert('Error updating TPLA details');
        }
      });

    for (const [tplaField, tplaDoc] of Object.entries(updatedTplaFields)) {
      if (Array.isArray(tplaDoc))
        for (let doc of updatedTplaFields[tplaField]) {
          let body = {
            doc_number: doc['doc_number']
          };
          let documents_url =
            ApiConstants.URL_TPLA_DOCUMENTS_UPDATE + doc['id'];

          if (body.doc_number !== '') {
            axios({
              method: 'put',
              url: documents_url,
              headers: {
                Authorization:
                  'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN),
                'Content-Type': 'application/json'
              },
              data: body
            })
              .then(response => {
                console.log(response);
              })
              .catch(error => {
                if (error.response) {
                  if (error.response.status === 401) {
                    alert('Entry is unauthorized');
                    localStorage.clear();
                    this.props.history.push('/login');
                  } else if (error.response.status === 403)
                    alert('Entry is prohibited');
                  else {
                    alert('Error updating TPLA details');
                  }
                } else {
                  alert('Error updating TPLA details');
                }
              });
          }
        }
    }
  };

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    console.log(this.props);
    if (this.props.tplaDetails.data !== undefined) {
      if (
        String(this.props.tplaDetails.data.id) !== nextProps.match.params.id
      ) {
        this.setState(
          {
            currentTplaId: nextProps.match.params.id,
            tplaUser: null
          },
          () => {
            this.fetchTplaDetails(nextProps.match.params.id, false);
          }
        );
      }
    }
  }

  componentDidMount() {
    if (this.state.currentTplaId === null) {
      this.setState({
        currentTplaId: this.props.match.params.id
      });
    }
    this.fetchTplaDetails(this.state.currentTplaId);
    document.getElementById('tpla-detail-page').click();
  }

  renderLocation = () => {
    let location = '--/--';
    if (
      this.originalTplaDetails.data !== undefined &&
      this.originalTplaDetails.data.geo_address !== null
    ) {
      location = Object.values(this.originalTplaDetails.data.geo_address).join(
        ', '
      );
    }
    return (
      <div>
        <div className="fields">
          <label>Location: </label>
          <span>{location}</span>
        </div>
        <br />
      </div>
    );
  };

  renderFields = (fields, childProps) => {
    if (this.props.tplaDetails.data) {
      let allfields = fields.map((field, index) => {
        if (this.props.tplaDetails.data.tpla_fields[`${field}`]) {
          return (
            <Editable
              title={titleCase(field)}
              type="text"
              id={field}
              value={
                this.props.tplaDetails.data.tpla_fields[`${field}`]['value']
              }
              size="50"
              editable={
                ApiConstants.TPLA_EDITABLE.includes(
                  this.props.tplaDetails.data.state
                ) &&
                ApiConstants.TPLA_FIELDS_EDITABLE.includes(
                  this.props.tplaDetails.data.tpla_fields[`${field}`]['status']
                )
                  ? true
                  : false
              }
              handleChange={this.handleChange}
              key={index}
              {...childProps}
            />
          );
        } else {
          return null;
        }
      });
      return allfields;
    }
  };

  renderHeaderFields = fields => {
    if (this.props.tplaDetails) {
      let allfields = fields.map((field, index) => {
        if (this.props.tplaDetails.data.user[`${field}`]) {
          return (
            <HeaderField
              title={
                field === 'id' ? titleCase('user_' + field) : titleCase(field)
              }
              type="text"
              id={field}
              value={this.props.tplaDetails.data.user[`${field}`]}
              size="10"
              key={index}
              style={{ textAlign: 'center' }}
            />
          );
        } else {
          return null;
        }
      });
      return allfields;
    }
  };

  renderOptionFields = (fields, childProps) => {
    if (this.props.tplaDetails.data) {
      let allfields = fields.map((field, index) => {
        if (this.props.tplaDetails.data.tpla_fields[`${field}`]) {
          if (
            this.props.tplaDetails.data.tpla_fields['residence_type'][
              'value'
            ] === 'Rented' &&
            field === 'residence_type'
          ) {
            ApiConstants.ALL_OPTION_FIELDS['residing_with'] =
              ApiConstants.RESIDING_WITH_IF_RENTED;
          } else if (
            this.props.tplaDetails.data.tpla_fields['residence_type'][
              'value'
            ] === 'Owned' &&
            field === 'residence_type'
          ) {
            ApiConstants.ALL_OPTION_FIELDS['residing_with'] =
              ApiConstants.RESIDING_WITH;
          }
          return (
            <OptionField
              title={titleCase(field)}
              id={field}
              editable={
                ApiConstants.TPLA_EDITABLE.includes(
                  this.props.tplaDetails.data.state
                ) &&
                ApiConstants.TPLA_FIELDS_EDITABLE.includes(
                  this.props.tplaDetails.data.tpla_fields[`${field}`]['status']
                )
                  ? true
                  : false
              }
              options={ApiConstants.ALL_OPTION_FIELDS[`${field}`]}
              value={
                this.props.tplaDetails.data.tpla_fields[`${field}`]['value']
              }
              handleChange={this.handleChange}
              key={index}
              {...childProps}
            />
          );
        } else {
          return null;
        }
      });
      return allfields;
    }
  };

  renderDocsPreview = (fields, childProps) => {
    if (this.props.tplaDetails.data) {
      let allfields = fields.map((field, index) => {
        if (this.props.tplaDetails.data.tpla_fields[field]) {
          let docs = this.props.tplaDetails.data.tpla_fields[field]['value'];
          let previewDisabled = true;
          let docNumbers = docs.map(doc => doc['doc_number']);
          for (let doc of docs) {
            if (doc['url1'] !== null || doc['url2'] !== null)
              previewDisabled = false;

            return (
              <DocsPreview
                docs={docs}
                title={titleCase(field)}
                id={field}
                docNumbers={docNumbers}
                editable={
                  ApiConstants.TPLA_EDITABLE.includes(
                    this.props.tplaDetails.data.state
                  ) &&
                  ApiConstants.TPLA_FIELDS_EDITABLE.includes(
                    this.props.tplaDetails.data.tpla_fields[`${field}`][
                      'status'
                    ]
                  )
                    ? true
                    : false
                }
                previewDisabled={previewDisabled}
                handleChange={this.handleChange}
                key={index}
                {...childProps}
              />
            );
          }
          return null;
        } else {
          return null;
        }
      });
      return allfields;
    }
  };

  onBasicSectionClick = () => {
    // check if section is not in editable state i.e "Edit" and then make API call
    if (this.state.isBasicEditable) {
      console.log('make API call to update changes');
      this.updateTplaDetails(ApiConstants.TPLA_BASIC);
    }
    this.setState(prevState => {
      return { isBasicEditable: !this.state.isBasicEditable };
    });
  };

  onLoanSectionClick = () => {
    // check if section is not in editable state i.e "Edit" and then make API call
    if (this.state.isLoanDetailsEditable) {
      console.log('make API call to update changes');
      this.updateTplaDetails(ApiConstants.TPLA_LOAN);
    }
    this.setState(prevState => {
      return { isLoanDetailsEditable: !this.state.isLoanDetailsEditable };
    });
  };

  onProfessionalSectionClick = () => {
    // check if section is not in editable state i.e "Edit" and then make API call
    if (this.state.isProfessionalDetailsEditable) {
      console.log('make API call to update changes');
      this.updateTplaDetails(ApiConstants.TPLA_PROFESSIONAL);
    }
    this.setState(prevState => {
      return {
        isProfessionalDetailsEditable: !this.state.isProfessionalDetailsEditable
      };
    });
  };

  onAddressSectionClick = () => {
    // check if section is not in editable state i.e "Edit" and then make API call
    if (this.state.isAddressEditable) {
      console.log('make API call to update changes');
      this.updateTplaDetails(ApiConstants.TPLA_ADDRESS);
    }
    this.setState(prevState => {
      return { isAddressEditable: !this.state.isAddressEditable };
    });
  };

  renderButtons = (sectionName, eventHandler) => {
    if (
      Object.entries(this.props.tplaDetails).length &&
      ApiConstants.TPLA_EDITABLE.includes(this.props.tplaDetails.data.state)
    ) {
      return (
        <div className="button btn-toolbar">
          <br />
          <CustomButton
            type="success"
            title={'Back'}
            onClick={this.props.history.goBack}
          />
          <CustomButton
            type="success"
            title={sectionName ? 'Save' : 'Edit'}
            onClick={eventHandler}
          />
        </div>
      );
    }
  };

  markTplaAsExtended = () => {
    const url = ApiConstants.URL_TPLA + '/mark_extended';

    let tplaDetailsCopy = this.props.tplaDetails;
    tplaDetailsCopy['data']['state'] = 'extended';
    let tplaIds = [this.props.tplaDetails.data.id];
    axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN),
        'Content-Type': 'application/json'
      },
      data: {
        tpla_ids: tplaIds
      }
    })
      .then(response => {
        console.log(response);
        this.props
          .setTplaDetails(tplaDetailsCopy)
          .then(() => window.location.reload());
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error in extending TPLA');
          }
        } else {
          alert('Error in extending TPLA');
        }
      });
  };

  renderMarkAsExtendedButton = () => {
    if (
      Object.entries(this.props.tplaDetails).length &&
      this.props.tplaDetails.data.state === 'basic'
    ) {
      return (
        <CustomButton
          type="success"
          title={'Extend TPLA'}
          onClick={this.markTplaAsExtended}
        />
      );
    }
  };

  renderExportTplaButton = () => {
    const onClickExport = () => {
      let tpla_id = this.props.match.params.id;
      let url = ApiConstants.URL_TPLA + tpla_id + '/export/';

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
        }
      })
        .then(response => {
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `tpla_${tpla_id}.zip`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 401) {
              alert('Entry is unauthorized');
              localStorage.clear();
              this.props.history.push('/login');
            } else if (error.response.status === 403)
              alert('Entry is prohibited');
            else {
              alert('Error exporting TPLA');
            }
          } else {
            alert('Error exporting TPLA');
          }
        });
    };
    return (
      <>
        <Button bsStyle="success" onClick={e => onClickExport()}>
          Export
        </Button>
      </>
    );
  };

  countFilledFields = () => {
    let tplaDetails = this.props.tplaDetails;
    if (tplaDetails.data) {
      let tplaFields = tplaDetails['data']['tpla_fields'];
      let totalFieldsCount = Object.entries(tplaFields).length;
      let filledFields = 0;
      for (let field in tplaFields) {
        if (tplaFields[field]['field_type'] === ApiConstants.TYPE_DOC_ARRAY) {
          if (tplaFields[field]['value'].length > 0) {
            let areDocsUploaded = false;
            for (let doc of tplaFields[field]['value']) {
              if (doc['url1'] !== null || doc['url2'] !== null)
                areDocsUploaded = true;
            }
            if (areDocsUploaded === true) filledFields += 1;
          }
        } else if (tplaFields[field]['value']) filledFields += 1;
      }
      return (
        <span style={{ float: 'left', margin: '15px' }}>
          {filledFields} of {totalFieldsCount} TPLA fields have been filled
        </span>
      );
    }
  };

  handleNewDocs = docs => {
    this.props.updatePreviewDocs(docs);
    this.props.setTabChangeStatus(false);
  };

  renderPreview = () => {
    let docsArePresent = this.props.docs.length > 0 ? true : false;
    let wasTabChanged = this.props.tabChange;
    if (docsArePresent && !wasTabChanged) {
      return (
        <div className="preview">
          <DocPreview
            docs={this.props.docs}
            curDoc={this.props.docs[0]}
            isInternalUpdate={false}
          />
        </div>
      );
    }
  };

  handleSendNotification = () => {
    this.props.sendingNotifications(true);
    let tplaId = this.props.match.params.id;
    const url = ApiConstants.URL_TPLA_SEND_NOTIFICATIONS;
    // In case of no changes, first notification template needs to be considered
    let reminder_id = 1;
    for (let template of this.props.reminderTemplateOptions) {
      if (template['title'] === this.props.templateSelection) {
        reminder_id = template['id'];
        break;
      }
    }
    let body = {
      tpla_id: tplaId,
      reminder_id: reminder_id,
      send_sms: this.props.notificationData.includes('SMS Notification')
        ? true
        : false,
      send_push: this.props.notificationData.includes('Push Notification')
        ? true
        : false
    };

    axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      },
      data: body
    })
      .then(response => {
        this.props.setNotificationData([]);
        this.props.sendingNotifications(false).then(() => {
          const message = Object.entries(response.data.data).map(
            item => item[1].message
          );
          alert(message);
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            const message =
              this.props.notificationData.length <= 0
                ? 'Please select some notification'
                : 'Notification could not be sent';
            alert(message);
          }
        } else {
          alert('Notification could not be sent');
        }
        this.props.sendingNotifications(false);
      });
  };

  handleNotificationChanges = (filterId, checkedOptions) => {
    this.props.setNotificationData(checkedOptions);
  };

  handleApplyNotifications = () => {
    // dummy function that does nothing, needed as it is called by the checkbox filter
  };

  renderNotificationDataPanel = () => {
    let options = this.props.reminderTemplateOptions.map(
      template => template.title
    );
    return (
      <>
        <h4> Notifications </h4>
        <br />
        <Tabs className="tabs">
          <TabList>
            <Tab selectedClassName="create_notifications">Create/Draft</Tab>
          </TabList>
          <TabPanel>
            <br />
            <OptionField
              title={'Template'}
              id={'notification_title'}
              options={options}
              value={this.props.templateSelection || options[0]}
              handleChange={this.handleTemplateSelection}
              editable={true}
              externalField={true}
              headerLabelStyle={{
                color: '#333',
                fontSize: '17px',
                width: '120px'
              }}
            />
            <div className="notifications">
              <CheckBoxFilter
                id="notification_type"
                options={['Push Notification', 'SMS Notification']}
                onChange={this.handleNotificationChanges}
                defaultCheckedOptions={this.props.notificationData}
                applyFilters={this.handleApplyNotifications}
                title={'Notification Type'}
                style={{ marginRight: '5px' }}
                headerLabelStyle={{
                  color: '#333',
                  marginBottom: '20px',
                  fontSize: '17px',
                  width: 'auto'
                }}
                childProps={{
                  templateSelection: this.props.templateSelection,
                  reminderTemplateOptions: this.props.reminderTemplateOptions
                }}
              />
            </div>
            <br />
            <div className="button btn-toolbar">
              <CustomButton
                title="Send Notification"
                disabled={this.props.notificationBeingSent}
                titleWhenDisabled="Sending..."
                onClick={
                  !this.props.notificationBeingSent
                    ? this.handleSendNotification
                    : null
                }
                type="success"
              />
            </div>
            <br />
            <br />
            <br />
          </TabPanel>
        </Tabs>
        <br />
      </>
    );
  };

  openNav = () => {
    const url =
      ApiConstants.URL_TPLA + `${this.props.match.params.id}/activity`;
    axios({
      method: 'get',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      }
    })
      .then(response => {
        this.props.setTimeLineData(response.data.data).then(() => {
          document.getElementById('mySidepanel').style.width = '25vw';
          document.getElementById('mySidepanel').style.zIndex = 3;
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            const message =
              this.props.timeLineData.length === 0
                ? 'No activities on this tpla yet'
                : 'Error fetching tpla activities';
            alert(message);
          }
        } else {
          alert('Error fetching tpla activities');
        }
      });
  };

  closeNav = () => {
    document.getElementById('mySidepanel').style.width = '0';
    document.getElementById('mySidepanel').style.zIndex = 1;
  };

  openNotes = () => {
    this.setState({
      notesPanelActive: true
    });
    document.getElementById('notespanel').style.width = '25vw';
    document.getElementById('notespanel').style.zIndex = 3;
  };

  closeNotes = () => {
    this.setState({
      notesPanelActive: false
    });
    document.getElementById('notespanel').style.width = '0';
    document.getElementById('notespanel').style.zIndex = 1;
  };

  render() {
    console.log('TplaDetail: render');
    let childProps = {
      isBasicEditable: this.state.isBasicEditable,
      isLoanDetailsEditable: this.state.isLoanDetailsEditable,
      isProfessionalDetailsEditable: this.state.isProfessionalDetailsEditable,
      isAddressEditable: this.state.isAddressEditable,
      handleNewDocs: this.handleNewDocs
    };
    return (
      <div
        id="tpla-detail-page"
        className="tpla-detail-page"
        onKeyDown={this.handleKeyDown}
        tabIndex="0"
      >
        <div className="split-left">
          <div>
            {Object.entries(this.props.tplaDetails).length ? (
              <div className="headers">
                <HeaderField
                  title="TPLA Id"
                  type="text"
                  id="tpla_id"
                  value={this.props.tplaDetails.data.id}
                  size="10"
                  style={{ textAlign: 'center' }}
                />
                {this.renderHeaderFields(['id'])}
                <HeaderField
                  title="TPLA State"
                  type="text"
                  id="tpla_state"
                  value={this.props.tplaDetails.data.state}
                  size="10"
                  style={{
                    textAlign: 'center',
                    backgroundColor:
                      ApiConstants.TPLA_STATE_COLOURS[
                        this.props.tplaDetails.data.state
                      ],
                    color: 'white'
                  }}
                />
                <CustomButton
                  className="floating-button"
                  onClick={this.openNav}
                  title={<FontAwesomeIcon icon={'bell'} size="2x" />}
                />
                <CustomButton
                  className="floating-button notes"
                  onClick={this.openNotes}
                  title={<FontAwesomeIcon icon={faComments} size="1x" />}
                />
              </div>
            ) : (
              <HeaderField
                title="TPLA Id"
                type="text"
                id="tpla_id"
                value="NA"
                size="10"
                style={{ textAlign: 'center' }}
              />
            )}
            <br />
            <div className="btn-toolbar">
              {this.renderMarkAsExtendedButton()}
              {this.renderExportTplaButton()}
              {this.countFilledFields()}
              {this.props.tplaDetails.data &&
                this.props.tplaDetails.data.tpla_fields && (
                  <a
                    className="whatsapp-block"
                    href={
                      ApiConstants.WHATSAPP_WEB_BASE_URL +
                      this.props.tplaDetails.data.tpla_fields.contact_number
                        .value
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="whatsapp-container"></div>
                    <div className="whatsapp-text">Chat</div>
                  </a>
                )}
            </div>
          </div>
          <h4> TPLA Details </h4>
          <br />
          <Tabs
            className="tabs"
            selectedIndex={this.props.tplaDetailsTabIndex}
            onSelect={tplaDetailsTabIndex => {
              this.props.setCurrentTabIndex(tplaDetailsTabIndex);
              this.props.setTabChangeStatus(true);
            }}
          >
            <TabList>
              <Tab selectedClassName="basic_details">Basic</Tab>
              <Tab selectedClassName="loan_details">Loan Details</Tab>
              {this.props.tplaDetails.data &&
                this.props.tplaDetails.data.state !== 'basic' && (
                  <>
                    <Tab selectedClassName="professional_details">
                      Professional Details
                    </Tab>
                    <Tab selectedClassName="address_details">Address</Tab>
                  </>
                )}
            </TabList>
            <TabPanel>
              <br />
              {this.renderFields(
                ['name', 'contact_number', 'email'],
                childProps
              )}
              {this.renderLocation()}
              {this.renderOptionFields(
                ['marital_status', 'qualification'],
                childProps
              )}
              {this.renderButtons(
                this.state.isBasicEditable,
                this.onBasicSectionClick
              )}
              <br />
              <br />
              <br />
              <br />
            </TabPanel>
            <TabPanel>
              <br />
              {this.renderFields(
                ['loan_amount', 'purpose', 'monthly_expense', 'monthly_income'],
                childProps
              )}
              {this.renderDocsPreview(['pan', 'bank_statement'], childProps)}
              {this.renderButtons(
                this.state.isLoanDetailsEditable,
                this.onLoanSectionClick
              )}
              <br />
              <br />
              <br />
              <br />
            </TabPanel>
            {this.props.tplaDetails.data &&
              this.props.tplaDetails.data.state !== 'basic' && (
                <>
                  <TabPanel>
                    <br />
                    {this.renderFields(
                      ['profession', 'place_work'],
                      childProps
                    )}
                    {this.renderDocsPreview(
                      ['pay_slip', 'it_returns'],
                      childProps
                    )}
                    {this.renderButtons(
                      this.state.isProfessionalDetailsEditable,
                      this.onProfessionalSectionClick
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                  </TabPanel>
                  <TabPanel>
                    <br />
                    {this.renderFields(
                      ['address', 'rent', 'num_dependents'],
                      childProps
                    )}
                    {this.renderOptionFields(
                      ['address_type', 'residence_type', 'residing_with'],
                      childProps
                    )}
                    {this.renderDocsPreview(
                      ['address_proof', 'residence_proof'],
                      childProps
                    )}
                    {this.renderButtons(
                      this.state.isAddressEditable,
                      this.onAddressSectionClick
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                  </TabPanel>
                </>
              )}
          </Tabs>
          <br />
          <br />
          {this.renderNotificationDataPanel()}
          <br />
        </div>
        <div className="split-right">
          <div id="notespanel" className="sidepanel">
            <CustomButton
              className="closebtn"
              onClick={this.closeNotes}
              title={
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <MdClose size="20" />
                </IconContext.Provider>
              }
              style={{
                width: 20,
                height: 20
              }}
            />
            {this.state.notesPanelActive ? (
              <TplaNotes tplaId={this.props.match.params.id} />
            ) : null}
          </div>
          <div id="mySidepanel" className="sidepanel">
            <CustomButton
              className="closebtn"
              onClick={this.closeNav}
              title={
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <MdClose size="30" />
                </IconContext.Provider>
              }
            />
            <TimeLineContainer
              timeLineData={this.props.timeLineData.reverse()}
            />
          </div>
          {this.renderPreview()}
        </div>
        {/* Insights Component */}
        {this.state.tplaUser ? (
          <UserInsights
            user={this.state.tplaUser}
            tplaId={this.props.match.params.id}
          />
        ) : null}
      </div>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(TplaDetail);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TplaDetail)
);
