import React, { Component } from "react";
import axios from "axios";
import * as ApiConstants from "../common/ApiConstants";
import { HorizontalBarChart, VerticalBarChart } from "../components/BarChart";
// import { PieChart } from "../components/DoughnutPieChart";
// import { LineChart } from "./../components/LineChart";
// import { PolarAreaChart } from "./../components/PolarAreaChart";
// import { RadarChart } from "./../components/RadarChart";
// import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import "./../css/Charts.css";

// class ChartChoices extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentChoice: "Visualisation"
//     };
//   }
//   handleSelect(eventKey) {
//     if (eventKey === "pie_chart") {
//       this.setState(prevState => {
//         return { currentChoice: "Pie Chart" };
//       });
//     } else if (eventKey === "doughnut_chart") {
//       this.setState(prevState => {
//         return { currentChoice: "Doughnut Chart" };
//       });
//     } else if (eventKey === "line_chart") {
//       this.setState(prevState => {
//         return { currentChoice: "Line Chart" };
//       });
//     }
//   }

//   render() {
//     return (
//       <Nav
//         bsStyle="pills"
//         activeKey="dropdown"
//         onSelect={choice => this.handleSelect(choice)}
//       >
//         <NavDropdown
//           eventKey="dropdown"
//           title={
//             this.state.currentChoice !== "Visualisation"
//               ? this.state.currentChoice
//               : "Visualisation"
//           }
//           id="visualisation"
//         >
//           <MenuItem eventKey="pie_chart">Pie Chart</MenuItem>
//           <MenuItem divider />
//           <MenuItem eventKey="doughnut_chart">Doughnut Chart</MenuItem>
//           <MenuItem divider />
//           <MenuItem eventKey="line_chart">Line Chart</MenuItem>
//         </NavDropdown>
//       </Nav>
//     );
//   }
// }
class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getData = () => {
    console.log(this.state);
    axios({
      url: ApiConstants.BASE_URL,
      method: "get"
    })
      .then(response => {
        this.setState(prevState => {
          return {};
        });
      })
      .catch(error => console.log(error));
  };

  componentDidMount() {
    // this.getData();
  }

  render() {
    return (
      <div className="chart-container">
        <div className="item">
          <HorizontalBarChart
            id="tpla_count"
            labels={[
              "Dec 2018",
              "Jan 2019",
              "Feb 2019",
              "March 2019",
              "April 2019",
              "Others"
            ]}
            data={[
              {
                title: "TPLA Count",
                values: ["0", "3681", "8729", "15863", "17301", "152254"],
                backgroundColor: "#A79AB2",
                hoverBackgroundColor: "#41463D"
              }
            ]}
            options={{ min: 0, max: 160000, stepSize: 10000 }}
            title="TPLA Count"
          />
        </div>
        <div className="item">
          <HorizontalBarChart
            id="count_users_mom"
            labels={[
              "March 2019",
              "April 2019",
              "May 2019",
              "Jun 2019",
              "Jul 2019",
              "Others"
            ]}
            data={[
              {
                title: "Count Users MOM",
                values: ["3739", "10813", "12196", "16558", "24427", "39776"],
                backgroundColor: "#A6CFD5",
                hoverBackgroundColor: "#41463D"
              }
            ]}
            options={{ min: 0, max: 40000, stepSize: 5000 }}
            title="Count Users MOM"
          />
        </div>
        <div className="item">
          <HorizontalBarChart
            id="deal_stats"
            labels={["29", "28", "27", "26", "25", "Others"]}
            data={[
              {
                title: "Deal Stats",
                values: ["81", "147", "17", "26", "20", "240"],
                backgroundColor: "#52B788",
                hoverBackgroundColor: "#41463D"
              }
            ]}
            options={{ min: 0, max: 300, stepSize: 50 }}
            title="Deal Stats"
          />
        </div>
        <div className="item">
          <VerticalBarChart
            id="tplas_daily"
            labels={[
              "Aug 8 2019",
              "Aug 9 2019",
              "Aug 10 2019",
              "Aug 11 2019",
              "Aug 12 2019",
              "Aug 13 2019"
            ]}
            data={[
              {
                title: "Count Unique",
                values: ["1033", "868", "899", "887", "895", "945"],
                backgroundColor: "#7A306C",
                hoverBackgroundColor: "#A79AB2"
              },
              {
                title: "Count",
                values: ["1591", "1341", "1364", "1369", "1347", "1488"],
                backgroundColor: "#7C98B3",
                hoverBackgroundColor: "#A6CFD5"
              }
            ]}
            options={{ min: 0, max: 2000, stepSize: 200 }}
            title="TPLA's Daily"
          />
        </div>
        <div className="item">
          <VerticalBarChart
            id="stats"
            labels={[
              "Aug 8 2019",
              "Aug 9 2019",
              "Aug 10 2019",
              "Aug 11 2019",
              "Aug 12 2019",
              "Aug 13 2019"
            ]}
            data={[
              {
                title: "Daily User Joins",
                values: ["1602", "1280", "1424", "1885", "1276", "1453"],
                backgroundColor: "magenta",
                hoverBackgroundColor: "pink"
              },
              {
                title: "OTP's Generated",
                values: ["825", "853", "824", "796", "897", "800"],
                backgroundColor: "#7C98B3",
                hoverBackgroundColor: "#A6CFD5"
              }
            ]}
            options={{ min: 0, max: 2000, stepSize: 200 }}
            title="Stats"
          />
        </div>
        <div className="item">
          <VerticalBarChart
            id="deals"
            labels={["Count Users After Deal", "Deal Clicks Users"]}
            data={[
              {
                title: "Count Users After Deal",
                values: ["53634", ""],
                backgroundColor: "lightblue",
                hoverBackgroundColor: "grey"
              },
              {
                title: "Deal Clicks Users",
                values: ["", "11141"],
                backgroundColor: "orange",
                hoverBackgroundColor: "grey"
              }
            ]}
            options={{ min: 0, max: 50000, stepSize: 10000 }}
            title="Deals"
          />
        </div>
        {/* <div className="item">
          <PieChart
            id="tpla_states"
            labels={["Basic", "Extended", "Pending", "Approved", "Rejected"]}
            data={[
              {
                values: ["10", "20", "30", "10", "15"],
                backgroundColor: ["grey", "purple", "yellow", "green", "red"]
              }
            ]}
            title="TPLA States Distribution"
          />
        </div> */}
        {/* <div className="item">
          <VerticalBarChart
            labels={["Jan", "Feb", "March"]}
            values={["10000", "8000", "12000"]}
            title="Value"
            color="#70CAD1"
          />
        </div> */}
        {/* <div className="item">
          <LineChart
            labels={["Jan", "Feb", "March"]}
            values={[1, 2, 3, 4]}
            title="Value"
            color="#70CAD1"
          />
        </div> */}
        {/* <div className="item">
          <PolarAreaChart
            labels={["Jan", "Feb", "March"]}
            values={[3, 1, 2]}
            title="Value"
            colors={["Cyan", "Yellow", "Orange"]}
          />
        </div>
        <div className="item">
          <RadarChart
            labels={["Jan", "Feb", "March"]}
            values={[3, 1, 2]}
            title="Value"
            color="Orange"
          />
        </div> */}
      </div>
    );
  }
}

export default Charts;
