import React, { Component } from 'react';
import { Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import axios from 'axios';
import * as ApiConstants from './../common/ApiConstants';
import './../css/Login.css';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const url = ApiConstants.URL_LOGIN;
    const accessToken = localStorage.getItem(ApiConstants.KEY_TOKEN) || '';
    if (!accessToken) {
      axios({
        method: 'post',
        url: url,
        header: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          username: this.state.username,
          password: this.state.password
        }
      })
        .then(response => {
          this.props.userHasAuthenticated(this.state.username, true);
          localStorage.setItem(ApiConstants.KEY_TOKEN, response.data.key);
          this.props.history.push('/tpla');
        })
        .catch(function(error) {
          console.log(error);
          if (error.response.status === 403) alert('Entry is prohibited');
          else {
            alert('Invalid username/password');
          }
        });
    } else {
      this.props.userHasAuthenticated(this.state.username, true);
      this.props.history.push('/tpla');
    }
  };

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username" bsSize="large">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            bsStyle="primary"
            disabled={!this.validateForm()}
            type="submit"
          >
            Login
          </Button>
        </form>
      </div>
    );
  }
}
