import React, { Component } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../css/Tpla.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

// Filter by TPLAId, UserId, FirstName, LastName, PhoneNumber
class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.props.defaultQuery
    };
  }

  handleChange = e => {
    let v = e.target.value;
    this.setState(prevState => {
      this.props.onChange(this.props.id, v);
      return { query: v };
    });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") this.props.applyFilters();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.defaultQuery !== state.query) {
      return {
        query: props.defaultQuery
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  render() {
    return (
      <div>
        <label>{this.props.title}</label>
        <br />
        <input
          min={0}
          id={this.props.id}
          value={this.state.query}
          placeholder={this.placeholder}
          type={this.props.type}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}

class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [this.props.defaultValue[0], this.props.defaultValue[1]]
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Added this check as props.defaultValue has '' value during initial render somehow
    if (typeof props.defaultValue !== "string") {
      if (
        JSON.stringify(props.defaultValue) !==
        JSON.stringify(state.defaultValue)
      ) {
        return {
          value: props.defaultValue
        };
      }
    }
    // Return null to indicate no change to state.
    return null;
  }

  handleChange = value => {
    this.props.onChange(this.props.id, value);
    this.setState(prevState => {
      return {
        value: value
      };
    });
  };

  handleAfterChange = value => {
    this.props.applyFilters();
  };

  render() {
    return (
      <>
        <label>{this.props.title}</label>
        <Range
          min={this.props.defaultMin}
          max={this.props.defaultMax}
          marks={{ 0: 0, 6: 6, 12: 12, 18: 18, 23: 23 }}
          tipFormatter={value => value}
          value={this.state.value}
          onChange={this.handleChange}
          onAfterChange={this.handleAfterChange}
        />
      </>
    );
  }
}

// Filter by LoanAmount, MonthlySalary,
class RangeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: this.props.defaultMin,
      max: this.props.defaultMax
    };
  }

  handleChange = e => {
    let [id, value] = [e.target.id, e.target.value];
    this.setState(prevState => {
      if (id === "min") {
        this.props.onChange(this.props.id, [value, prevState.max]);
        return { min: value };
      } else if (id === "max") {
        this.props.onChange(this.props.id, [prevState.min, value]);
        return { max: value };
      }
      return {};
    });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") this.props.applyFilters();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.defaultMin !== state.min && props.defaultMax !== state.max) {
      return {
        min: props.defaultMin,
        max: props.defaultMax
      };
    } else if (props.defaultMin !== state.min) {
      return {
        min: props.defaultMin
      };
    } else if (props.defaultMax !== state.max) {
      return {
        max: props.defaultMax
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  render() {
    let separator = this.props.multiLine ? <br /> : " - ";
    let maxInputStyle = {
      marginTop: this.props.multiLine ? "5px" : "0px"
    };
    return (
      <div>
        <label>{this.props.title}</label> <br />
        <input
          id="min"
          size="10"
          min={0}
          placeholder="min"
          value={this.state.min}
          type={this.props.type}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          max={this.props.type === "datetime-local" ? "2020-12-31T23:59" : null}
        />
        {separator}
        <input
          id="max"
          size="10"
          min={0}
          style={maxInputStyle}
          value={this.state.max}
          placeholder="max"
          type={this.props.type}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}
class CheckBoxFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedOptions: this.props.defaultCheckedOptions
    };
  }

  handleChange = e => {
    let value = e.target.value;
    let newCheckedOptions = this.state.checkedOptions;
    if (e.target.checked) {
      if (!newCheckedOptions.includes(value)) newCheckedOptions.push(value);
    } else {
      let i = newCheckedOptions.indexOf(value);
      if (i !== -1) newCheckedOptions.splice(i, 1);
    }
    this.props.onChange(this.props.id, newCheckedOptions);
    this.setState(prevState => {
      this.props.applyFilters();
      return { checkedOptions: newCheckedOptions };
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.defaultCheckedOptions) !==
      JSON.stringify(state.checkedOptions)
    ) {
      return {
        checkedOptions: props.defaultCheckedOptions
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  render() {
    let disablePush = false;
    let disableSms = false;
    if (
      this.props.childProps &&
      this.props.childProps.reminderTemplateOptions.length > 0
    ) {
      disablePush = this.props.childProps.reminderTemplateOptions[0]
        .support_push;
      disableSms = this.props.childProps.reminderTemplateOptions[0].support_sms;
      for (let template of this.props.childProps.reminderTemplateOptions) {
        if (template["title"] === this.props.childProps.templateSelection) {
          disablePush = !template.support_push;
          disableSms = !template.support_sms;
        }
      }
    }
    return (
      <>
        <label style={this.props.headerLabelStyle}>{this.props.title}</label>
        <br />
        <div className="checkboxes">
          {this.props.options.map(optionName => {
            if (optionName === "Push Notification") {
              return (
                <div key={optionName} className="checkboxitems">
                  <input
                    style={this.props.style || { marginRight: "3px" }}
                    id={optionName}
                    type="checkbox"
                    checked={this.state.checkedOptions.includes(optionName)}
                    value={optionName}
                    onChange={this.handleChange}
                    disabled={disablePush}
                  />
                  <label htmlFor={optionName}>{optionName}</label>
                </div>
              );
            } else if (optionName === "SMS Notification") {
              return (
                <div key={optionName} className="checkboxitems">
                  <input
                    style={this.props.style || { marginRight: "3px" }}
                    id={optionName}
                    type="checkbox"
                    checked={this.state.checkedOptions.includes(optionName)}
                    value={optionName}
                    onChange={this.handleChange}
                    disabled={disableSms}
                  />
                  <label htmlFor={optionName}>{optionName}</label>
                </div>
              );
            } else {
              return (
                <div key={optionName} className="checkboxitems">
                  <input
                    style={this.props.style || { marginRight: "3px" }}
                    id={optionName}
                    type="checkbox"
                    checked={this.state.checkedOptions.includes(optionName)}
                    value={optionName}
                    onChange={this.handleChange}
                  />
                  <label htmlFor={optionName}>{optionName}</label>
                </div>
              );
            }
          })}
        </div>
      </>
    );
  }
}

export { SearchFilter, RangeSlider, RangeFilter, CheckBoxFilter };
