import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as ApiConstants from './../common/ApiConstants';
import {
  Input,
  Button,
  Label,
  Radio,
  CheckBox,
  RangeSlider
} from '../components/Filters1';
import './../css/Tpla.css';

class TplaFilterForm extends Component {
  constructor(props) {
    super(props);
    this.setInitialState(props);

    //  Variables mapping for URL query params
    this.user_id = 'user_id';
    this.tpla_id = 'tpla_id';
    this.phoneNumber = 'phone';
    this.userName = 'name';
    this.location = 'state';
    this.loanAmount = 'loan_amount';

    //  Functions binding
    this.handleTplaId = this.handleTplaId.bind(this);
    this.handleUserId = this.handleUserId.bind(this);
    this.handleUserName = this.handleUserName.bind(this);
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleLoanAmount = this.handleLoanAmount.bind(this);
    this.handleMonthlyIncome = this.handleMonthlyIncome.bind(this);
    this.handleInsightGenerated = this.handleInsightGenerated.bind(this);
    this.handleTplaState = this.handleTplaState.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleFilledFields = this.handleFilledFields.bind(this);
    this.handleFilledFieldsDragEnds = this.handleFilledFieldsDragEnds.bind(
      this
    );
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
  }

  static contextTypes = {
    router: PropTypes.object
  };

  setInitialState(props) {
    let params = new URLSearchParams(props.history.location.search);
    this.state = {
      urlParams: {
        tpla_id: '',
        user_id: '',
        loan_amount: [],
        monthly_income: [],
        name: '',
        phone: '',
        location: '',
        filled_fields: [],
        state: [],
        insights_generated: '',
        created_at: ['', '']
      },
      dataFetched: false,
      currentPage:
        params.get('page') !== undefined ? params.get('page') : 1 || 1,
      sizePerPage: props.sizePerPage || 30,
      tplaId: params.get('tpla_id') || '',
      userId: params.get('user_id') || '',
      userName: params.get('name') || '',
      phoneNumber: params.get('phone') || '',
      location: params.get('location') || '',
      selectedInsightGenerated: params.get('insights_generated') || '',
      loanMinAmount:
        params.get('loan_amount') !== null
          ? params.get('loan_amount').split(',')[0]
          : '' || '',
      loanMaxAmount:
        params.get('loan_amount') !== null
          ? params.get('loan_amount').split(',')[1]
          : '' || '',
      monthlyIncomeMin:
        params.get('monthly_income') !== null
          ? params.get('monthly_income').split(',')[0]
          : '' || '',
      monthlyIncomeMax:
        params.get('monthly_income') !== null
          ? params.get('monthly_income').split(',')[1]
          : '' || '',
      startDate:
        params.get('created_at') !== null
          ? params.get('created_at').split(',')[0]
          : '' || '',
      endDate:
        params.get('created_at') !== null
          ? params.get('created_at').split(',')[1]
          : '' || '',
      selectFilledFields:
        params.get('filled_fields') !== null &&
        params.get('filled_fields').split(',').length === 2
          ? params
              .get('filled_fields')
              .split(',')
              .map(Number)
          : [0, 0] || [0, 0],
      selectedState:
        params.get('state') !== null
          ? params
              .get('state')
              .split(',')
              .filter(String)
          : [] || [],
      insightGeneratedList: ['true', 'false', 'not null'],
      filledFieldsList: [0, 8, 12, 18, 23],
      stateList: ['basic', 'extended', 'pending', 'approved', 'rejected']
    };
  }

  componentDidMount() {
    this.submitForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.currentPage !== this.state.currentPage ||
      prevProps.sizePerPage !== this.state.sizePerPage
    ) {
      this.setState(
        {
          currentPage: prevProps.currentPage,
          sizePerPage: prevProps.sizePerPage
        },
        () => {
          this.submitForm();
        }
      );
    }
  }

  submitForm() {
    let urlParams = this.getUrlParams();
    let urlQueryParams = new URLSearchParams(urlParams).toString();
    this.props.showLoader(true);
    axios
      .get(ApiConstants.URL_TPLA, {
        params: urlParams,
        headers: {
          Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
        }
      })
      .then(response => {
        this.props.showLoader(false);
        const rows = this.props.serializeRows(response.data);
        const totalCount = response.data.data.total;
        this.props.setTplaRows({
          rows: rows,
          totalCount: totalCount
        });
        this.props.history.push({
          pathname: '/tpla',
          search: `?${urlQueryParams}`
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error fetching TPLA details');
          }
        } else {
          alert('Error fetching TPLA details');
        }
      });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.submitForm();
  }

  clearForm() {
    this.setState(
      {
        urlParams: {
          tpla_id: '',
          user_id: '',
          loan_amount: [],
          monthly_income: [],
          created_at: ['', ''],
          name: '',
          phone: '',
          location: '',
          filled_fields: [],
          state: [],
          insights_generated: ''
        },
        dataFetched: false,
        currentPage: 1,
        sizePerPage: 30,
        tplaId: '',
        userId: '',
        loanMinAmount: '',
        loanMaxAmount: '',
        monthlyIncomeMin: '',
        monthlyIncomeMax: '',
        startDate: '',
        endDate: '',
        userName: '',
        phoneNumber: '',
        location: '',
        selectFilledFields: [0, 0],
        selectedState: [],
        selectedInsightGenerated: '',
        insightGeneratedList: ['true', 'false', 'not null'],
        filledFieldsList: [0, 8, 12, 18, 23],
        stateList: ['basic', 'extended', 'pending', 'approved', 'rejected']
      },
      () => {
        this.submitForm();
      }
    );
  }

  handleClearForm(event) {
    event.preventDefault();
    this.clearForm();
  }

  handleTplaId(event) {
    let value = event.target.value;
    this.setState({
      tplaId: value
      // urlParams: {...this.state.urlParams, tpla_id: value}
    });
  }

  handleUserId(event) {
    let value = event.target.value;
    this.setState({
      userId: value
      // urlParams: {...this.state.urlParams, user_id: value}
    });
  }

  handleLoanAmount(event) {
    let value = event.target.value;
    let min_value;
    let max_value;
    if (event.target.id === 'loanMinAmount') {
      // this.setState({
      //   loanMinAmount: value
      // })
      min_value = value;
      max_value = this.state.loanMaxAmount;
    }
    if (event.target.id === 'loanMaxAmount') {
      // this.setState({
      //   loanMaxAmount: value
      // })
      min_value = this.state.loanMinAmount;
      max_value = value;
    }
    this.setState({
      [event.target.id]: value
      // urlParams: {...this.state.urlParams, loan_amount: [min_value, max_value]}
    });
  }

  handleMonthlyIncome(event) {
    let value = event.target.value;
    if (event.target.id === 'monthlyIncomeMin') {
      this.setState({
        monthlyIncomeMin: value
      });
    }
    if (event.target.id === 'monthlyIncomeMax') {
      this.setState({
        monthlyIncomeMax: value
      });
    }
  }

  handleUserName(event) {
    let value = event.target.value;
    this.setState({
      userName: value
      // urlParams: {...this.state.urlParams, name: value}
    });
  }

  handlePhoneNumber(event) {
    let value = event.target.value;
    this.setState({
      phoneNumber: value
      // urlParams: {...this.state.urlParams, phone: value}
    });
  }

  handleLocation(event) {
    let value = event.target.value;
    this.setState({
      location: value
      // urlParams: {...this.state.urlParams, location: value}
    });
  }

  handleInsightGenerated(event) {
    let value = event.target.value;
    this.setState(
      {
        selectedInsightGenerated: value
        // urlParams: {...this.state.urlParams, insights_generated: value}
      },
      () => {
        this.submitForm();
      }
    );
  }

  handleTplaState(event) {
    let value = event.target.value;
    let selectedStates = [...this.state.selectedState];
    if (this.state.selectedState.indexOf(value) === -1) {
      selectedStates.push(value);
    } else {
      selectedStates.splice(selectedStates.indexOf(value), 1);
    }
    this.setState(
      {
        selectedState: selectedStates
        // urlParams: {...this.state.urlParams, state: selectedStates}
      },
      () => {
        this.submitForm();
      }
    );
  }

  handleFilledFields(value) {
    this.setState({
      selectFilledFields: value
      //  urlParams: {...this.state.urlParams, filled_fields: value}
    });
  }

  handleFilledFieldsDragEnds(value) {
    this.setState(
      {
        selectFilledFields: value
        // urlParams: {...this.state.urlParams, filled_fields: value}
      },
      () => {
        this.submitForm();
      }
    );
  }

  handleDate(event) {
    let startDate;
    let endDate;
    let value = event.target.value;
    if (event.target.id === 'startDate') {
      startDate = value;
      endDate = this.state.endDate;
      this.setState({
        startDate: value
      });
    }
    if (event.target.id === 'endDate') {
      startDate = this.state.startDate;
      endDate = value;
      this.setState({
        endDate: value
      });
    }
    // this.setState({
    //   urlParams: {...this.state.urlParams, created_at: [startDate, endDate]}
    // })
  }

  getUrlParams() {
    // let regEx = /^\d{4}-\d{2}-\d{2}$/;
    let regEx = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
    let urlParams = { ...this.state.urlParams };
    if (urlParams.created_at[0].length > 0) {
      if (urlParams.created_at[0].match(regEx)) {
        //  converting date string to datetime
        urlParams.created_at[0] = new Date(
          urlParams.created_at[0]
        ).toISOString();
      } else {
        alert('Incorret start date format');
        return false;
      }
    }

    if (urlParams.created_at[1].length > 0) {
      if (urlParams.created_at[1].match(regEx)) {
        //  converting date string to datetime
        urlParams.created_at[1] = new Date(
          urlParams.created_at[1]
        ).toISOString();
      } else {
        alert('Incorret end date format');
        return false;
      }
    }

    urlParams.page = [undefined, null].includes(this.state.currentPage)
      ? 1
      : this.state.currentPage;
    urlParams.count = [undefined, null].includes(this.state.sizePerPage)
      ? 30
      : this.state.sizePerPage;
    urlParams.tpla_id = this.state.tplaId;
    urlParams.user_id = this.state.userId;
    urlParams.name = this.state.userName;
    urlParams.phone = this.state.phoneNumber;
    urlParams.location = this.state.location;
    urlParams.insights_generated = this.state.selectedInsightGenerated;
    urlParams.monthly_income = [
      this.state.monthlyIncomeMin,
      this.state.monthlyIncomeMax
    ].join(',');
    urlParams.loan_amount = [
      this.state.loanMinAmount,
      this.state.loanMaxAmount
    ].join(',');
    urlParams.state = this.state.selectedState.join(',');
    let filled_fields = this.state.selectFilledFields;
    if (filled_fields[1] === 0) {
      filled_fields = [0, ''];
    }

    urlParams.filled_fields = filled_fields.join(',');
    urlParams.created_at = [this.state.startDate, this.state.endDate].join(',');
    return urlParams;
  }

  render() {
    return (
      <form onSubmit={this.handleFormSubmit} method="get">
        <Input
          title="Tpla Id"
          htmlID={this.tpla_id}
          name={this.tpla_id}
          type="number"
          value={this.state.tplaId}
          placeholder="Enter Tpla Id"
          showLabel={true}
          labelFor={this.tpla_id}
          handleChange={this.handleTplaId}
        />
        <Input
          title="User Id"
          htmlID={this.user_id}
          name={this.user_id}
          type="number"
          value={this.state.userId}
          placeholder="Enter User Id"
          showLabel={true}
          labelFor={this.user_id}
          handleChange={this.handleUserId}
        />
        <Label name="loanMinAmount" title="Loan Amount" />
        <Input
          htmlID="loanMinAmount"
          name="loanMinAmount"
          type="number"
          value={this.state.loanMinAmount}
          placeholder="min"
          showLabel={false}
          labelFor="loanMinAmount"
          handleChange={this.handleLoanAmount}
        />
        <Input
          htmlID="loanMaxAmount"
          name="loanMaxAmount"
          type="number"
          value={this.state.loanMaxAmount}
          placeholder="max"
          showLabel={false}
          labelFor="loanMaxAmount"
          handleChange={this.handleLoanAmount}
        />
        <Label name="monthlyIncomeMin" title="Monthly Income" />
        <Input
          htmlID="monthlyIncomeMin"
          name="monthlyIncomeMin"
          type="number"
          value={this.state.monthlyIncomeMin}
          placeholder="min"
          showLabel={false}
          labelFor="monthlyIncomeMax"
          handleChange={this.handleMonthlyIncome}
        />
        <Input
          htmlID="monthlyIncomeMax"
          name="monthlyIncomeMax"
          type="number"
          value={this.state.monthlyIncomeMax}
          placeholder="max"
          showLabel={false}
          labelFor="loanMaxAmount"
          handleChange={this.handleMonthlyIncome}
        />
        <Input
          htmlID="userName"
          name="userName"
          type="text"
          title="Username"
          value={this.state.userName}
          placeholder=""
          showLabel={true}
          labelFor="userName"
          handleChange={this.handleUserName}
        />
        <Input
          htmlID="phoneNumber"
          name="phoneNumber"
          type="number"
          title="Phone Numbers"
          value={this.state.phoneNumber}
          placeholder=""
          showLabel={true}
          labelFor="phoneNumber"
          handleChange={this.handlePhoneNumber}
        />
        <Input
          htmlID="location"
          name="location"
          type="text"
          title="Location"
          value={this.state.location}
          placeholder=""
          showLabel={true}
          labelFor="location"
          handleChange={this.handleLocation}
        />
        <div className="form-group">
          <Label name="insightGenerated" title="Insight Generated" />
          {this.state.insightGeneratedList.map((option, key) => {
            return (
              <Radio
                key={key}
                htmlID={option}
                name="state"
                title={option}
                value={option}
                // checked={ this.state.selectedState.indexOf(state) > -1 }
                showLabel={true}
                labelFor={option}
                handleChange={this.handleInsightGenerated}
                selected={this.state.selectedInsightGenerated === option}
              />
            );
          })}
        </div>
        <div className="state-list form-group">
          <Label name="State" title="State" />
          {this.state.stateList.map((state, key) => {
            return (
              <CheckBox
                key={key}
                htmlID={state}
                name="state"
                title={state}
                value={state}
                checked={this.state.selectedState.indexOf(state) > -1}
                showLabel={true}
                labelFor={state}
                handleChange={this.handleTplaState}
              />
            );
          })}
        </div>
        <div className="filled-fields form-group filter_container">
          <Label name="filled_fields" title="Filled Fields" />
          <RangeSlider
            defaultMin={this.state.filledFieldsList[0]}
            defaultMax={
              this.state.filledFieldsList[
                this.state.filledFieldsList.length - 1
              ]
            }
            name="filled_fields"
            marks={this.state.filledFieldsList.reduce(
              (x, y) => ((x[y] = y), x),
              {}
            )}
            value={this.state.selectFilledFields}
            defaultValue={[0, 0]}
            handleChange={this.handleFilledFields}
            handleAfterChange={this.handleFilledFieldsDragEnds}
            count={2}
          />
        </div>
        <Label name="dateMin" title="Date" />
        <Input
          htmlID="startDate"
          name="startDate"
          type="text"
          value={this.state.startDate}
          placeholder="Starting date YYYY-MM-DD"
          showLabel={false}
          labelFor="startDate"
          handleChange={this.handleDate}
        />
        <Input
          htmlID="endDate"
          name="endDate"
          type="text"
          value={this.state.endDate}
          placeholder="End date YYYY-MM-DD"
          showLabel={false}
          labelFor="endDate"
          handleChange={this.handleDate}
        />
        <div className="filter-buttons">
          <Button className="filter-button" title="Apply" />
          <Button
            className="filter-button"
            title="Clear All"
            onClick={this.handleClearForm}
          />
        </div>
      </form>
    );
  }
}

export { TplaFilterForm };
