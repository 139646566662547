export const BASE_URL = 'https://api.udhaar.co';
// export const BASE_URL = "https://api-dev.udhaar.co";
// export const BASE_URL = 'http://localhost:8000';

// export const URL_LOGIN = BASE_URL + '/rest-auth/login/';
export const URL_LOGIN = BASE_URL + '/udhaar/dashboard/login/';
export const URL_TPLA = BASE_URL + '/udhaar/dashboard/tpla/';
export const URL_GET_REMINDER_TEMPLATES =
  BASE_URL + '/udhaar/dashboard/tpla/reminder_templates';
export const URL_TPLA_DOCUMENTS_UPDATE =
  BASE_URL + '/udhaar/dashboard/user_documents/';
export const URL_TPLA_SEND_NOTIFICATIONS =
  BASE_URL + '/udhaar/dashboard/tpla/notify_user';
export const WHATSAPP_WEB_BASE_URL = 'https://wa.me/91';

// Banking Insights
export const USER_INSIGHT_URL = URL_TPLA + 'insights/';

export const KEY_TOKEN = 'token';

// Field Types
export const TYPE_INT = 'int';
export const TYPE_DECIMAL = 'decimal';
export const TYPE_STRING = 'str';
export const TYPE_BOOLEAN = 'bool';
export const TYPE_DOC = 'doc';
export const TYPE_DOC_ARRAY = 'doc_array';
export const TYPE_LIST = [
  TYPE_INT,
  TYPE_DECIMAL,
  TYPE_STRING,
  TYPE_BOOLEAN,
  TYPE_DOC,
  TYPE_DOC_ARRAY
];

// TPLA States
export const STATE_BASIC = 'basic';
export const STATE_EXTENDED = 'extended';
export const STATE_FILLED = 'filled';
export const STATE_CORRECTION = 'correction';
export const STATE_REJECTED = 'rejected';
export const STATE_APPROVED = 'approved';

// Field Status
export const STATUS_APPROVED = 0;
export const STATUS_EMPTY = 1;
export const STATUS_REJECTED = 2;
export const STATUS_PENDING = 3;
export const STATUS_CORRECTION = 4;
export const STATUS_LIST = [
  STATUS_APPROVED,
  STATUS_EMPTY,
  STATUS_REJECTED,
  STATUS_PENDING,
  STATUS_CORRECTION
];

// TPLA Editable States
export const TPLA_EDITABLE = [STATE_EXTENDED, STATE_CORRECTION, STATE_FILLED];

// TPLA Editable Field States
export const TPLA_FIELDS_EDITABLE = [
  STATUS_EMPTY,
  STATUS_PENDING,
  STATUS_CORRECTION
];

// Basic Fields
export const TPLA_BASIC = [
  'name',
  'contact_number',
  'email',
  'marital_status',
  'qualification'
];

// Loan Details
export const TPLA_LOAN = [
  'loan_amount',
  'purpose',
  'monthly_expense',
  'monthly_income',
  'pan',
  'bank_statement'
];

// Professional Details
export const TPLA_PROFESSIONAL = [
  'profession',
  'place_work',
  'pay_slip',
  'it_returns'
];

// Address Details
export const TPLA_ADDRESS = [
  'address',
  'address_type',
  'residence_type',
  'rent',
  'num_dependents',
  'residing_with',
  'address_proof',
  'residence_proof'
];

// TPLA State Representation Colours
export const TPLA_STATE_COLOURS = {
  basic: 'grey',
  extended: 'purple',
  filled: 'yellow',
  correction: 'orange',
  rejected: 'red',
  approved: 'green'
};

// TextArea Fields
export const MULTILINE_TPLA_FIELDS = ['address'];

//Tpla Field Options
export const ADDRESS_TYPE = ['', 'Permanent', 'Present'];
export const RESIDENCE_TYPE = ['', 'Rented', 'Owned'];
export const RESIDING_WITH = [
  '',
  'With Family',
  'With Friends',
  'Alone',
  'Other'
];
export const RESIDING_WITH_IF_RENTED = [
  '',
  'With Family',
  'With Friends',
  'Alone',
  'Other',
  'In PG'
];
export const MARITAL_STATUS = [
  '',
  'Married',
  'Single',
  'Divorced',
  'Separated'
];
export const QUALIFICATION = [
  '',
  'PhD',
  'Masters',
  'Bachelors',
  'High School',
  '10th',
  'Below 10th'
];

export const ALL_OPTION_FIELDS = {
  address_type: ADDRESS_TYPE,
  residence_type: RESIDENCE_TYPE,
  residing_with: RESIDING_WITH,
  marital_status: MARITAL_STATUS,
  qualification: QUALIFICATION
};

export const MIN_COUNT_FILLED_FIELDS = 0;
export const MAX_COUNT_FILLED_FIELDS = 23;

// TPLA What
export const TPLA_NOTES = 'notes';
