import React, { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../css/Tpla.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

// Filter by TPLAId, UserId, FirstName, LastName, PhoneNumber

class BaseFilter extends Component {
  handleKeyPress = e => {
    if (e.key === 'Enter') this.props.applyFilters();
  };
}

const Input = props => {
  return (
    <div className="form-group filter_container">
      {props.showLabel === true ? (
        <label htmlFor={props.labelFor} className="form-label">
          {props.title}
        </label>
      ) : null}
      <input
        className="form-input"
        id={props.htmlID}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
      />
    </div>
  );
};

const Radio = props => {
  return (
    <div className="form-group filter_container">
      <input
        className="form-input"
        id={props.htmlID}
        name={props.name}
        type="radio"
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        checked={props.selected}
      />
      {props.showLabel === true ? (
        <label htmlFor={props.labelFor} className="form-label">
          {props.title}
        </label>
      ) : null}
    </div>
  );
};

const CheckBox = props => {
  return (
    <div className="form-group form-group-reverse filter_container">
      {props.showLabel === true ? (
        <label htmlFor={props.labelFor} className="form-label">
          {props.title}
        </label>
      ) : null}
      <input
        className="form-input"
        id={props.htmlID}
        name={props.name}
        type="checkbox"
        value={props.value}
        checked={props.checked}
        onChange={props.handleChange}
      />
    </div>
  );
};

const Label = props => {
  return (
    <label htmlFor={props.name} className="form-label">
      {props.title}
    </label>
  );
};

const Button = props => {
  return (
    <button
      className={props.className}
      name={props.name}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
};
const RangeSlider = props => {
  return (
    <Range
      min={props.defaultMin}
      max={props.defaultMax}
      marks={props.marks}
      name={props.name}
      count={props.count}
      tipFormatter={value => value}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.handleChange}
      onAfterChange={props.handleAfterChange}
    />
  );
};
export { RangeSlider, Input, Button, Label, CheckBox, Radio };
