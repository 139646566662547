import React, { Component } from "react";
import Chart from "chart.js";
import { MdFullscreen } from "react-icons/md";
import { IconContext } from "react-icons";
import CustomButton from "./Button";
import isEqual from "lodash.isequal";
import "../css/Chart.css";

export class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.myChart.data.labels = this.props.labels;
      this.myChart.data.datasets = this.props.data.map(dataset => ({
        label: dataset.title,
        data: dataset.values,
        backgroundColor: dataset.backgroundColor
      }));
      this.myChart.update();
    }
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "doughnut",
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: "right"
        },
        title: {
          display: true,
          text: this.props.title
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data.map(dataset => ({
          label: dataset.title,
          data: dataset.values,
          backgroundColor: dataset.backgroundColor
        }))
      }
    });
  }

  handleFullScreen = () => {
    let id = this.props.id;
    let elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  render() {
    return (
      <>
        <CustomButton
          onClick={this.handleFullScreen}
          title={
            <IconContext.Provider
              value={{ color: "black", className: "react-icons" }}
            >
              <MdFullscreen size="30" />
            </IconContext.Provider>
          }
          type="default"
        />
        <canvas id={this.props.id} ref={this.canvasRef} />
      </>
    );
  }
}

export class PieChart extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.myChart.data.labels = this.props.labels;
      this.myChart.data.datasets = this.props.data.map(dataset => ({
        label: dataset.title,
        data: dataset.values,
        backgroundColor: dataset.backgroundColor
      }));
      this.myChart.update();
    }
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: "pie",
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: "right"
        },
        title: {
          display: true,
          text: this.props.title
        }
      },
      data: {
        labels: this.props.labels,
        datasets: this.props.data.map(dataset => ({
          label: dataset.title,
          data: dataset.values,
          backgroundColor: dataset.backgroundColor
        }))
      }
    });
  }

  handleFullScreen = () => {
    let id = this.props.id;
    let elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  render() {
    return (
      <>
        {this.props.isFullscreenRequired && (
          <CustomButton
            onClick={this.handleFullScreen}
            title={
              <IconContext.Provider
                value={{ color: "black", className: "react-icons" }}
              >
                <MdFullscreen size="30" />
              </IconContext.Provider>
            }
            type="default"
          />
        )}
        <canvas id={this.props.id} ref={this.canvasRef} />
      </>
    );
  }
}
