import React, { Component } from "react";
import { TimeLineItem } from "./TimeLineItem";
import "./../css/TimeLine.css";

export class TimeLineContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="timeline-container">
        {this.props.timeLineData.map((activity, idx) => (
          <TimeLineItem activity={activity} key={idx} />
        ))}
      </div>
    );
  }
}