import React, { Component } from "react";
import { Button } from "react-bootstrap";

class CustomButton extends Component {
  render() {
    return (
      <Button
        bsStyle={this.props.type}
        onClick={this.props.onClick}
        bsClass={this.props.className}
        disabled={this.props.disabled}
      >
        {this.props.disabled ? this.props.titleWhenDisabled : this.props.title}
      </Button>
    );
  }
}

export default CustomButton;
