import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import * as ApiConstants from './../common/ApiConstants';
import queryString from 'query-string';
import Loader from 'react-loader-spinner';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
// import TplaFilter from "./TplaFilter";
import { TplaFilterForm } from './TplaFilter1';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { PieChart } from '../components/DoughnutPieChart';
import isEqual from 'lodash.isequal';
import { connect } from 'react-redux';
import {
  setLoadingStatus,
  setRowsAndCountOfTplas,
  setTplaFilters
} from './../actions/Actions';
import './../css/Tpla.css';

const mapStateToProps = state => {
  return {
    rows: state.rowsAndCount.rows,
    loading: state.loadingStatus.loading,
    total: state.rowsAndCount.total,
    filters: state.tplaFilters.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isLoading: loading => dispatch(setLoadingStatus(loading)),
    setRowsAndCount: tplaListData =>
      dispatch(setRowsAndCountOfTplas(tplaListData)),
    setTplaFilters: tplaFilters => {
      return Promise.resolve(dispatch(setTplaFilters(tplaFilters)));
    }
  };
};

function getTplaStatesDistribution(tplaList) {
  const tplaStates = {
    basic: 0,
    extended: 0,
    pending: 0,
    approved: 0,
    rejected: 0
  };
  for (let doc of tplaList) {
    tplaStates[doc.state] += 1;
  }
  return Object.values(tplaStates);
}

function getFiltersFromQuery(queryParamsStr) {
  let filters = queryString.parse(queryParamsStr);
  if (filters.states) {
    filters.states = filters.states.split(',');
  }
  if (filters.filled_fields) {
    filters.filled_fields = filters.filled_fields
      .split(',')
      .map(item => parseInt(item, 10));
  }
  return filters;
}

function getQueryFromFilters(filtersObj) {
  let query = '';
  for (let field in filtersObj) {
    if (Array.isArray(filtersObj[field])) {
      if (filtersObj[field].length > 0) {
        query += `${field}=` + filtersObj[`${field}`] + '&';
      }
    } else if (filtersObj[field] !== '') {
      if (field === 'dateMin' || field === 'dateMax') {
        let d = new Date(filtersObj[field]);
        filtersObj[field] = new Date(d - d.getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 16);
      }
      query += `${field}=` + filtersObj[`${field}`] + '&';
    }
  }
  if (query.endsWith('&')) query = query.substring(0, query.length - 1);
  return query;
}

function getEmptyFilters() {
  return {};
}

class Tpla extends Component {
  constructor(props) {
    super(props);
    let urlParams = new URLSearchParams(props.history.location.search);
    this.state = {
      markedTplasForExtend: [],
      currentPage: urlParams.get('page') || 1,
      sizePerPage: urlParams.get('count') || 30,
      sortField: null,
      sortOrder: null
    };
  }

  columns = [
    {
      dataField: 'id',
      text: 'TPLA Id',
      headerStyle: this.headerStyle,
      formatter: this.id_formatter,
      sort: true,
      events: {
        onClick: e => {
          e.stopPropagation();
        }
      }
    },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: this.headerStyle
    },
    {
      dataField: 'location',
      text: 'Location',
      headerStyle: this.headerStyle
    },
    {
      dataField: 'monthly_income',
      text: 'Monthly Income',
      headerStyle: this.headerStyle,
      sort: true
    },
    {
      dataField: 'loan_amount',
      text: 'Loan Amount',
      headerStyle: this.headerStyle,
      sort: true
    },
    {
      dataField: 'state',
      text: 'State',
      headerStyle: this.headerStyle,
      formatter: this.statusIndicator
    },
    {
      dataField: 'filled_fields',
      text: 'Filled Fields',
      headerStyle: this.headerStyle,
      sort: true
    },
    {
      dataField: 'created_at',
      text: 'Date',
      headerStyle: this.headerStyle,
      sort: true
    },
    {
      dataField: '',
      text: '',
      isdummyField: true,
      formatter: this.exportButton,
      headerStyle: this.headerStyle
    }
  ];

  id_formatter(e, row) {
    let link = `/tpla/${row.id}`;
    return (
      <Button bsStyle="link" href={link}>
        {row.id}
      </Button>
    );
  }

  statusIndicator(cell) {
    return (
      <div
        style={{
          backgroundColor: ApiConstants.TPLA_STATE_COLOURS[cell],
          color: 'white',
          width: '80px',
          height: '25px',
          display: 'inline-block',
          borderRadius: '20px',
          lineHeight: '22px'
        }}
      >
        {cell}
      </div>
    );
  }

  headerStyle() {
    return {
      backgroundColor: '#c8e6c9',
      textAlign: 'center'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let filters = getEmptyFilters();
    let curSearchParams = getFiltersFromQuery(props.location.search);

    for (let field in curSearchParams) {
      if (curSearchParams[field]) {
        filters[field] = curSearchParams[field];
      }
    }

    return { filters: filters };
  }

  serialize = api_response => {
    let formatted_tplas = [];
    for (let tpla of api_response['data']['items']) {
      let formatted_tpla = {};
      formatted_tpla['name'] = tpla['name'];
      formatted_tpla['id'] = tpla['id'];
      formatted_tpla['monthly_income'] = tpla['monthly_income'];
      formatted_tpla['loan_amount'] = tpla['loan_amount'];
      formatted_tpla['state'] = tpla['state'];
      formatted_tpla['filled_fields'] = tpla['filled_fields'];
      let date = new Date(tpla['created_at']);
      formatted_tpla['created_at'] = date.toString().substring(4, 21);
      formatted_tpla['location'] =
        tpla['geo_address'] && tpla['geo_address']['locality']
          ? tpla['geo_address']['locality']
          : '';
      formatted_tplas.push(formatted_tpla);
    }
    return formatted_tplas;
  };

  exportButton(cell, row) {
    const onClickExport = (e, cell, row) => {
      let tpla_id = row['id'];
      let url = ApiConstants.URL_TPLA + tpla_id + '/export/';

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
        }
      })
        .then(response => {
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `tpla_${tpla_id}.zip`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 401) {
              alert('Entry is unauthorized');
              localStorage.clear();
              this.props.history.push('/login');
            } else if (error.response.status === 403)
              alert('Entry is prohibited');
            else {
              alert('Error exporting TPLA');
            }
          } else {
            alert('Error exporting TPLA');
          }
        });
    };
    return (
      <div className="export_button">
        <Button bsStyle="success" onClick={e => onClickExport(e, cell, row)}>
          Export
        </Button>
      </div>
    );
  }

  fetchTplaList = () => {
    this.props.isLoading(true);
    axios({
      method: 'get',
      url: ApiConstants.URL_TPLA,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN)
      },
      params: {
        page: this.props.filters.page || 1,
        count: this.props.filters.count || 30,
        monthly_income: `${this.props.filters.monthlyIncomeMin || ''},${this
          .state.filters.monthlyIncomeMax || ''}`,
        loan_amount: `${this.props.filters.loanAmountMin || ''},${this.props
          .filters.loanAmountMax || ''}`,
        created_at: `${this.props.filters.dateMin || ''},${this.props.filters
          .dateMax || ''}`,
        ...(this.props.filters.sort ? { sort: this.props.filters.sort } : {}),
        ...(this.props.filters.tplaId
          ? { tpla_id: this.props.filters.tplaId }
          : {}),
        ...(this.props.filters.userId
          ? { user_id: this.props.filters.userId }
          : {}),
        ...(this.props.filters.phoneNumber
          ? { phone: this.props.filters.phoneNumber }
          : {}),
        ...(this.props.filters.userName
          ? { name: this.props.filters.userName }
          : {}),
        ...(this.props.filters.states
          ? { state: this.props.filters.states.toString() }
          : {}),
        ...(this.props.filters.location
          ? { location: this.props.filters.location }
          : {}),
        ...(this.props.filters.filled_fields
          ? { filled_fields: this.props.filters.filled_fields.toString() }
          : {})
      }
    })
      .then(response => {
        const rows = this.serialize(response.data);
        const totalCount = response.data.data.total;
        // Should see how to do this with single update
        this.props.isLoading(false);
        this.props.setRowsAndCount({
          rows: rows,
          totalCount: totalCount
        });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error fetching TPLA details');
          }
        } else {
          alert('Error fetching TPLA details');
        }
      });
  };

  componentDidUpdate(prevProps) {
    // let prevFilters = getEmptyFilters();
    // let newFilters = getEmptyFilters();
    // let prevSearchParams = getFiltersFromQuery(prevProps.location.search);
    // let curSearchParams = getFiltersFromQuery(this.props.location.search);
    // for (let field in prevSearchParams) {
    //   if (prevSearchParams[field]) prevFilters[field] = prevSearchParams[field];
    // }
    // for (let field in curSearchParams) {
    //   if (curSearchParams[field]) newFilters[field] = curSearchParams[field];
    // }
    // if (!isEqual(prevFilters, newFilters)) {
    //   if (Object.entries(newFilters).length) {
    //     this.props.setTplaFilters(newFilters).then(() => {
    //       this.fetchTplaList();
    //     });
    //   } else this.fetchTplaList();
    // }
  }

  componentDidMount() {
    const newFilters = getEmptyFilters();
    let filters = getFiltersFromQuery(this.props.location.search);

    for (let field in filters) {
      if (filters[field]) newFilters[field] = filters[field];
    }
    // if (Object.entries(filters).length) {
    //   this.props.setTplaFilters(newFilters).then(() => {
    //     this.fetchTplaList();
    //   });
    // } else this.fetchTplaList();
  }

  handleApplyFilters = f => {
    console.log('handleApplyFilters', f);
    f['page'] = 1;
    f['count'] = 30;
    let filterParams = getQueryFromFilters(f);
    this.props.setTplaFilters(f).then();
    this.props.history.push({
      pathname: '/tpla',
      search: `?${filterParams}`
    });
  };

  getCurrentPage() {
    return this.state.currentPage;
  }

  handlePageChange = (page, sizePerPage) => {
    let f = this.props.filters;
    f['page'] = page;
    f['count'] = sizePerPage;
    let filterParams = getQueryFromFilters(f);
    let urlParams = new URLSearchParams(this.props.history.location.search);
    urlParams.set('page', page);
    this.setState(
      {
        currentPage: page
      },
      () => {
        console.log(page);
      }
    );
    // console.log(this.props)
    this.props.setTplaFilters(f).then(() => {
      this.props.history.push({
        pathname: '/tpla',
        // search: `?${filterParams}`
        search: urlParams.toString()
      });
    });
  };

  handleSizePerPageChange = (sizePerPage, page) => {
    let f = this.props.filters;
    f['page'] = page;
    f['count'] = sizePerPage;
    let filterParams = getQueryFromFilters(f);
    let urlParams = new URLSearchParams(this.props.history.location.search);
    urlParams.set('count', sizePerPage);
    this.setState(
      {
        sizePerPage: sizePerPage
      },
      () => {
        console.log(sizePerPage);
      }
    );
    this.props.setTplaFilters(f).then(() => {
      this.props.history.push({
        pathname: '/tpla',
        // search: `?${filterParams}`
        search: urlParams.toString()
      });
    });
  };

  handleOnSelect = (row, isSelect) => {
    let tplasToExtend = this.state.markedTplasForExtend;
    if (!isSelect) {
      let index = tplasToExtend.indexOf(row.id);
      if (index > -1) {
        tplasToExtend.splice(index, 1);
      }
    } else {
      tplasToExtend.push(row.id);
    }
    this.setState(prevState => {
      return { markedTplasForExtend: tplasToExtend };
    });
  };

  handleOnSelectAll = (isSelect, rows) => {
    if (!isSelect) {
      this.setState(prevState => {
        return { markedTplasForExtend: [] };
      });
    } else {
      let tplasToExtend = this.state.markedTplasForExtend;
      for (let tpla of rows) {
        tplasToExtend.push(tpla.id);
      }
      this.setState(prevState => {
        return { markedTplasForExtend: tplasToExtend };
      });
    }
  };

  extendTpla = () => {
    const url = ApiConstants.URL_TPLA + '/mark_extended';
    let tplaIds = this.state.markedTplasForExtend;
    // deepcopy to be done here
    let tplaList = cloneDeep(this.props.rows);
    for (let tpla of tplaList) {
      if (tplaIds.includes(tpla['id'])) {
        tpla['state'] = 'extended';
      }
    }
    axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: 'Token ' + localStorage.getItem(ApiConstants.KEY_TOKEN),
        'Content-Type': 'application/json'
      },
      data: {
        tpla_ids: tplaIds
      }
    })
      .then(response => {
        this.setState(
          prevState => ({
            markedTplasForExtend: []
          }),
          () => window.location.reload()
        );
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Entry is unauthorized');
            localStorage.clear();
            this.props.history.push('/login');
          } else if (error.response.status === 403)
            alert('Entry is prohibited');
          else {
            alert('Error in extending TPLA');
          }
        } else {
          alert('Error in extending TPLA');
        }
      });
  };

  renderActions = () => {
    if (this.state.markedTplasForExtend.length > 0) {
      return (
        <div>
          <Button
            bsStyle="success"
            onClick={e => {
              this.extendTpla();
            }}
          >
            Mark as extended
          </Button>
        </div>
      );
    }
  };

  renderFilters = () => {
    let childProps = {
      ...this.props.filters
    };
    return (
      <TplaFilterForm
        applyFilters={this.handleApplyFilters}
        {...childProps}
        serializeRows={this.serialize}
        setTplaRows={this.props.setRowsAndCount}
        history={this.props.history}
        showLoader={this.props.isLoading}
        currentPage={this.getCurrentPage()}
        sizePerPage={this.state.sizePerPage}
      />
    );
  };

  handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === 'sort') {
      console.log(sortField, sortOrder);
      let activeFilters = this.props.filters;
      let urlParams = new URLSearchParams(this.props.history.location.search);
      urlParams.set('sort', `${sortField},${sortOrder}`);
      activeFilters['sort'] = `${sortField},${sortOrder}`;
      let filterParams = getQueryFromFilters(activeFilters);
      this.setState(
        {
          sortField: sortField,
          sortOrder: sortOrder
        },
        () => {
          this.props.setTplaFilters(activeFilters).then(() => {
            this.props.history.push({
              pathname: '/tpla',
              // search: `?${filterParams}`
              search: urlParams.toString()
            });
          });
        }
      );
    }
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Results
      </span>
    );
    let selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      style: { background: 'silver' }
    };
    let params = new URLSearchParams(this.props.history.location.search);
    let paginationFact = paginationFactory({
      // page: parseInt(this.props.filters.page, 10) || 1,
      page: parseInt(params.get('page'), 10) || 1,
      sizePerPage: parseInt(this.props.filters.count, 10) || 30,
      sizePerPageList: [10, 30, 50],
      totalSize: this.props.total,
      hideSizePerPage: false,
      showTotal: true,
      paginationTotalRenderer: customTotal,
      onPageChange: this.handlePageChange,
      onSizePerPageChange: this.handleSizePerPageChange
    });
    return (
      <>
        <div className="tpla_page">
          <div className="tpla_filter">{this.renderFilters()}</div>
          <div className="tpla_list">
            {this.props.loading && (
              <div className="spinner">
                <Loader
                  visible={this.props.loading}
                  type="BallTriangle"
                  color="#008000"
                  height={60}
                  width={60}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                />
              </div>
            )}

            {this.props.loading || (
              <>
                <div className="tpla-chart-container">
                  <div className="tpla-chart-item">
                    <PieChart
                      id="states"
                      labels={[
                        'Basic',
                        'Extended',
                        'Pending',
                        'Approved',
                        'Rejected'
                      ]}
                      data={[
                        {
                          values: getTplaStatesDistribution(this.props.rows),
                          backgroundColor: [
                            'grey',
                            'purple',
                            'yellow',
                            'green',
                            'red'
                          ]
                        }
                      ]}
                      title="TPLA States Distribution"
                    />
                  </div>
                </div>
                <div>
                  <br />
                  {this.renderActions()}
                  <br />
                  <BootstrapTable
                    striped
                    hover
                    keyField="id"
                    selectRow={selectRow}
                    data={this.props.rows}
                    columns={this.columns}
                    rowStyle={{ textAlign: 'center' }}
                    pagination={paginationFact}
                    onTableChange={this.handleTableChange}
                    remote={{ pagination: true, filter: false, sort: false }}
                    sort={{
                      dataField: this.state.sortField,
                      order: this.state.sortOrder
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Tpla);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tpla));
