import React, { Component } from 'react';
import CustomButton from './Button';
import { MULTILINE_TPLA_FIELDS } from '../common/ApiConstants';
import './../css/TplaFields.css';
import cloneDeep from 'lodash/cloneDeep';
import {
  TPLA_ADDRESS,
  TPLA_BASIC,
  TPLA_PROFESSIONAL,
  TPLA_LOAN
} from '../common/ApiConstants';

class HeaderField extends Component {
  render() {
    return (
      <div className="headers">
        <label>{this.props.title}</label>
        <input
          type={this.props.type}
          id={this.props.id}
          value={this.props.value}
          size={this.props.size}
          style={this.props.style}
          disabled
        ></input>
      </div>
    );
  }
}

class Editable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  onChange = e => {
    let [id, value] = [e.target.id, e.target.value];
    this.setState(prevState => {
      return { value: value };
    });
    this.props.handleChange(id, value);
  };
  render() {
    // To reuse this component, a prop 'externalField' has to be passed as true
    let toggleField = 'externalField';
    if (TPLA_BASIC.includes(this.props.id)) toggleField = 'isBasicEditable';
    else if (TPLA_LOAN.includes(this.props.id))
      toggleField = 'isLoanDetailsEditable';
    else if (TPLA_PROFESSIONAL.includes(this.props.id))
      toggleField = 'isProfessionalDetailsEditable';
    else if (TPLA_ADDRESS.includes(this.props.id))
      toggleField = 'isAddressEditable';
    let field;
    // To distinguish between the text area and an input field component, a prop 'multiLine' has to be passed as true
    if (
      MULTILINE_TPLA_FIELDS.includes(this.props.id) ||
      this.props.multiLine === true
    ) {
      field = (
        <div className="textAreaContainer fields">
          <label htmlFor={this.props.id}>{this.props.title}</label>
          <textarea
            id={this.props.id}
            disabled={!this.props[`${toggleField}`] || !this.props.editable}
            wrap="hard"
            value={this.state.value}
            onChange={this.onChange}
          >
            {this.state.value}
          </textarea>
        </div>
      );
    } else {
      field = (
        <div className="fields">
          <label>{this.props.title}</label>
          <input
            type={this.props.type}
            id={this.props.id}
            value={this.state.value}
            size={this.props.size}
            disabled={!this.props[`${toggleField}`] || !this.props.editable}
            onChange={this.onChange}
          ></input>
        </div>
      );
    }
    return (
      <div>
        {field}
        <br />
      </div>
    );
  }
}

class DocsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docNumbers: this.props.docNumbers.toString()
    };
  }
  onChange = e => {
    let [id, value] = [e.target.id, e.target.value];
    let updatedDocs = this.props.docs;
    updatedDocs[0]['doc_number'] = value;
    this.setState(
      prevState => ({ docNumbers: value }),
      this.props.handleChange(id, updatedDocs)
    );
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.docNumbers.toString() !== this.state.docNumbers.toString()) {
      this.setState({ docNumbers: nextProps.docNumbers.toString() });
    }
  }

  getNewDocs = () => {
    let docs = cloneDeep(this.props.docs);
    let modDocs = [];
    let newDoc = {};
    for (let doc of docs) {
      if (doc['url1'] !== null) {
        newDoc = { src: doc['url1'], alt: 'NA' };
        modDocs.push(newDoc);
      }
      if (doc['url2'] !== null) {
        newDoc = { src: doc['url2'], alt: 'NA' };
        modDocs.push(newDoc);
      }
    }
    this.props.handleNewDocs(modDocs);
  };

  render() {
    let toggleField = '';
    if (TPLA_BASIC.includes(this.props.id)) toggleField = 'isBasicEditable';
    else if (TPLA_LOAN.includes(this.props.id))
      toggleField = 'isLoanDetailsEditable';
    else if (TPLA_PROFESSIONAL.includes(this.props.id))
      toggleField = 'isProfessionalDetailsEditable';
    else if (TPLA_ADDRESS.includes(this.props.id))
      toggleField = 'isAddressEditable';
    return (
      <>
        <div className="fields">
          <label>{this.props.title}</label>
          <input
            type="text"
            style={{ marginRight: '100px' }}
            id={this.props.id}
            value={this.state.docNumbers}
            disabled={!this.props[`${toggleField}`] || !this.props.editable}
            onChange={this.onChange}
          ></input>
          {this.props.previewDisabled || (
            <CustomButton
              type="success"
              title={'Preview'}
              onClick={this.getNewDocs}
            />
          )}
        </div>
        <br />
      </>
    );
  }
}

class OptionField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: this.props.value
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.selectValue !== this.state.selectValue) {
      this.setState({ selectValue: nextProps.selectValue });
    }
  }

  onChange(e) {
    let [id, value] = [e.target.id, e.target.value];
    this.setState({
      selectValue: e.target.value
    });
    this.props.handleChange(id, value);
  }
  render() {
    // To reuse this component, a prop 'externalField' has to be passed as true
    let toggleField = 'externalField';
    if (TPLA_BASIC.includes(this.props.id)) toggleField = 'isBasicEditable';
    else if (TPLA_LOAN.includes(this.props.id))
      toggleField = 'isLoanDetailsEditable';
    else if (TPLA_PROFESSIONAL.includes(this.props.id))
      toggleField = 'isProfessionalDetailsEditable';
    else if (TPLA_ADDRESS.includes(this.props.id))
      toggleField = 'isAddressEditable';

    let options = this.props.options.map((item, index) => {
      if (this.state.selectValue === '' && item === '') {
        return (
          <option value="" key={index}>
            {' '}
            Select an option{' '}
          </option>
        );
      } else if (item !== '') {
        return (
          <option value={item} key={index}>
            {' '}
            {item}{' '}
          </option>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="fields">
        <div>
          <label style={this.props.headerLabelStyle} htmlFor={this.props.id}>
            {this.props.title}
          </label>
          <select
            name={this.props.title}
            value={this.state.selectValue}
            id={this.props.id}
            onChange={this.onChange}
            disabled={!this.props[`${toggleField}`] || !this.props.editable}
          >
            {options}
          </select>
        </div>
        <br />
      </div>
    );
  }
}

export { HeaderField, Editable, DocsPreview, OptionField };
