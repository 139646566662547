export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const TPLA_LIST_UPDATE = "TPLA_LIST_UPDATE";
export const TPLA_FILTERS_UPDATE = "TPLA_FILTERS_UPDATE";
export const TPLA_DETAILS_UPDATE = "TPLA_DETAILS_UPDATE";
export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";
export const SENDING_NOTIFICATIONS = "SENDING_NOTIFICATIONS";
export const UPDATING_PREVIEW_DOCS = "UPDATING_PREVIEW_DOCS";
export const SET_TAB_CHANGE_STATUS = "SET_TAB_CHANGE_STATUS";
export const SET_CURRENT_TAB_INDEX = "SET_CURRENT_TAB_INDEX";
export const SET_TIMELINE_DATA = "SET_TIMELINE_DATA";
export const SET_REMINDER_TEMPLATES = "SET_REMINDER_TEMPLATES";
export const SET_TEMPLATE_SELECTION = "SET_TEMPLATE_SELECTION";

// Action creators dispatched from Login.js

export function login(credentials) {
  return {
    type: LOGIN,
    credentials
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

// Action creators dispatched from Tpla.js

export function setLoadingStatus(loading) {
  return {
    type: SET_LOADING_STATUS,
    loading
  };
}

export function setRowsAndCountOfTplas(tplaListData) {
  return {
    type: TPLA_LIST_UPDATE,
    tplaListData
  };
}

export function setTplaFilters(filters) {
  return {
    type: TPLA_FILTERS_UPDATE,
    filters
  };
}

// Action creators dispatched from TplaDetail.js

export function setTplaDetails(tplaData) {
  return {
    type: TPLA_DETAILS_UPDATE,
    tplaData
  };
}

export function setNotificationData(notificationData) {
  return {
    type: SET_NOTIFICATION_DATA,
    notificationData
  };
}

export function sendingNotifications(value) {
  return {
    type: SENDING_NOTIFICATIONS,
    value
  };
}

export function updatePreviewDocs(docs) {
  return {
    type: UPDATING_PREVIEW_DOCS,
    docs
  };
}

export function setTabChangeStatus(value) {
  return {
    type: SET_TAB_CHANGE_STATUS,
    value
  };
}

export function setCurrentTabIndex(index) {
  return {
    type: SET_CURRENT_TAB_INDEX,
    index
  };
}

export function setTimeLineData(timeLineData) {
  return {
    type: SET_TIMELINE_DATA,
    timeLineData
  };
}

export function setReminderTemplates(reminderTemplates) {
  return {
    type: SET_REMINDER_TEMPLATES,
    reminderTemplates
  }
}

export function setTemplateSelection(selectedOption) {
  return {
    type: SET_TEMPLATE_SELECTION,
    selectedOption
  }
}