import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./errors/NotFound";
import Login from "./onboarding/Login";
import Tpla from "./tpla/Tpla";
import Charts from "./charts/Charts";
import TplaDetail from "./tpla/TplaDetail";
import CheckAuthRoute from "./CheckAuth.js";

export default ({ childProps }) => (
  <Switch>
    <CheckAuthRoute exact path="/" component={Tpla} />
    <Route
      exact
      path="/login"
      render={props => <Login {...props} {...childProps} />}
    />
    <CheckAuthRoute exact path="/tpla" component={Tpla} />
    <CheckAuthRoute exact path="/tpla/:id" component={TplaDetail} />
    <CheckAuthRoute exact path="/charts" component={Charts} />
    <Route component={NotFound} />
  </Switch>
);
