import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as ApiConstants from './common/ApiConstants';

const CheckAuthRoute = ({
  component: Component,
  props: childProps,
  ...rest
}) => {
  let isLoggedIn = localStorage.getItem(ApiConstants.KEY_TOKEN);
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} {...childProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default CheckAuthRoute;
