import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Routes from './Routes';
import * as ApiConstants from './common/ApiConstants';
import { connect } from 'react-redux';
import { login, logout } from './actions/Actions';
import { compose } from 'redux';
import './css/App.css';

const mapDispatchToProps = dispatch => {
  return {
    authLogin: credentials => dispatch(login(credentials)),
    authLogout: () => dispatch(logout())
  };
};

class App extends Component {
  userHasAuthenticated = (username, authenticated) => {
    this.props.authLogin({
      username: username,
      authenticated: authenticated
    });
    if (authenticated) {
      localStorage.setItem('current_user', username);
    }
  };

  handleLogout = event => {
    this.props.authLogout();
    localStorage.clear();
    this.props.history.push('/login');
  };

  render() {
    const childProps = {
      userHasAuthenticated: this.userHasAuthenticated
    };
    let isLoggedIn = localStorage.getItem(ApiConstants.KEY_TOKEN);
    return (
      <div className="App">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Udhaar</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullLeft>
              <NavItem>v2.3</NavItem>
              {isLoggedIn ? (
                <LinkContainer to="/tpla">
                  <NavItem>TPLA</NavItem>
                </LinkContainer>
              ) : (
                ''
              )}
            </Nav>
            <Nav pullLeft>
              <LinkContainer to="/charts">
                <NavItem>Charts</NavItem>
              </LinkContainer>
            </Nav>
            <Nav pullRight>
              {isLoggedIn ? (
                <NavItem onClick={this.handleLogout}>Logout</NavItem>
              ) : (
                <LinkContainer to="/login">
                  <NavItem>Login</NavItem>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

// export default compose(withRouter, connect(null, mapDispatchToProps))(App);
export default withRouter(connect(null, mapDispatchToProps)(App));
