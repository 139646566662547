import React, { Component } from 'react';
import axios from 'axios';
import CustomButton from './Button';
import './../css/TplaFields.css';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash/cloneDeep';
import Loader from 'react-loader-spinner';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { Document, Page, pdfjs, View, Text } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DocPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curDoc: this.props.curDoc,
      curIndex: 0,
      docs: this.props.docs,
      numPages: null,
      isInternalUpdate: this.props.isInternalUpdate
    };
    this.getBlobURL = this.getBlobURL.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  getBlobURL(url) {
    return axios({
      method: 'get',
      url: url,
      responseType: 'blob'
    });
    // let fileURL;
    // var oReq = new XMLHttpRequest();
    // oReq.open('GET', url, true);
    // oReq.responseType = 'blob';
    // oReq.onload = function() {
    //   // // Once the file is downloaded, open a new window with the PDF
    //   // // Remember to allow the POP-UPS in your browser
    //   let file = new Blob([oReq.response], { type: 'application/pdf' });
    //   fileURL = URL.createObjectURL(file);
    //   console.log(fileURL)
    // };
    // oReq.send();
  }
  onPdfPassword(callback, reason) {
    //  reason can be either 1 or  2. 1 is entering the password, 2 is incorrect password.
    const password = prompt('Enter the password to open this PDF file.');
    if (password === null) {
      return false;
    }
    switch (reason) {
      case 1: {
        // eslint-disable-next-line no-alert
        callback(password);
        break;
      }
      case 2: {
        // eslint-disable-next-line no-alert
        callback(password);
        break;
      }
      default:
    }
  }

  onDocumentLoadSuccess({ numPages }) {
    console.log(numPages);
    this.setState({ numPages });
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.isInternalUpdate) {
      let newDocs = cloneDeep(props.docs);
      for (const [index, newDoc] of Object.entries(props.docs)) {
        let oldDoc = state[index];
        if (!isEqual(newDoc, oldDoc)) {
          newDocs[index] = newDoc;
        }
      }
      return {
        docs: newDocs,
        curDoc: newDocs[0],
        curIndex: 0,
        isInternalUpdate: false
      };
    } else return { isInternalUpdate: false };
  }

  getDocType = doc => {
    if (doc['src'].toLowerCase().search('.pdf') !== -1) return true;
    else return false;
  };

  nextDoc = () => {
    let curIndex = this.state.curIndex + 1;
    if (curIndex < this.state.docs.length) {
      this.setState(prevState => ({
        curDoc: this.state.docs[curIndex],
        curIndex: curIndex,
        isInternalUpdate: true
      }));
    }
  };

  prevDoc = () => {
    let curIndex = this.state.curIndex - 1;
    if (curIndex >= 0) {
      this.setState(prevState => ({
        curDoc: this.state.docs[curIndex],
        curIndex: curIndex,
        isInternalUpdate: true
      }));
    }
  };

  renderLoader = () => {
    return (
      <div className="preview-spinner">
        <Loader
          visible={true}
          type="BallTriangle"
          color="#008000"
          height={60}
          width={60}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
      </div>
    );
  };

  renderDocControlButtons() {
    return (
      <div className="btn-toolbar">
        {this.state.curIndex > 0 && (
          <CustomButton
            type="primary"
            title="Previous Doc"
            onClick={this.prevDoc}
          />
        )}
        {this.state.curIndex < 1 && (
          <CustomButton
            type="primary"
            title="Next Doc"
            onClick={this.nextDoc}
          />
        )}
        <span style={{ float: 'left', margin: '15px' }}>
          Showing {this.state.curIndex + 1} of {this.state.docs.length} docs
        </span>
      </div>
    );
  }

  renderPdf = () => {
    let isPdf = this.getDocType(this.state.curDoc);
    // let url = this.state.curDoc['src'];
    // let url;
    // this.getBlobURL(this.props.curDoc['src']).then(response => {
    //   console.log(response.data)
    //     let file = new Blob([response.data], { type: 'application/pdf' });
    //   url = URL.createObjectURL(file);
    // });

    const numPages = this.state.numPages;

    if (isPdf) {
      return (
        <>
          <div className="pdf-container">
            <Document
              file={this.state.curDoc['src']}
              onLoadSuccess={this.onDocumentLoadSuccess}
              onPassword={this.onPdfPassword}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1.4}
                />
              ))}
            </Document>
          </div>
          <p>
            This browser does not support PDFs. Please download the PDF to view
            it:
            <a href={this.state.curDoc['src']}>Download PDF</a>.
          </p>
        </>
      );
    }
  };

  renderImage = () => {
    let isImage = !this.getDocType(this.state.curDoc);
    let url = this.state.curDoc['src'];
    if (isImage) {
      return (
        <div className="container">
          <ReactPanZoom image={url} alt="NA" />
        </div>
      );
    }
  };

  render() {
    return (
      <>
        {this.renderDocControlButtons()}
        {this.renderPdf()}
        {this.renderImage()}
      </>
    );
  }
}

export default DocPreview;
