import React, { Component } from "react";
import pushNotification from "./../assets/push_notification.svg";
import smsNotification from "./../assets/sms_notification.svg";
import tplaAdded from "./../assets/tpla_added.svg";
import tplaFieldUpdate from "./../assets/tpla_field_update.svg";
import tplaStateUpdate from "./../assets/tpla_state_update.svg";
import "./../css/TimeLine.css";

export class TimeLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  formatDate = inputDate => {
    const created_at = new Date(inputDate).toString().substring(4, 21);
    return created_at;
  };
  constructMessage = () => {
    return `${
      ["sms", "push"].includes(this.props.activity.what)
        ? " notification sent"
        : " " + this.props.activity.action
    } ${
      this.props.activity.value !== null
        ? "to " + this.props.activity.value
        : ""
    } ${"by " + this.props.activity.by_user}`;
  };
  render() {
    return (
      <div className="timeline-item">
        <div className="timeline-item-content">
          <time>{this.formatDate(this.props.activity.created_at)}</time>
          <p>
            <strong>{this.props.activity.what}</strong>
            {this.constructMessage()}
          </p>
          <br />
          <span className="circle">
            <div style={{ textAlign: "center", marginTop: "7px" }}>
              {this.props.activity.what === "sms" ? (
                <img
                  width="20px"
                  height="20px"
                  src={smsNotification}
                  alt="NA"
                ></img>
              ) : this.props.activity.what === "push" ? (
                <img
                  width="20px"
                  height="20px"
                  src={pushNotification}
                  alt="NA"
                ></img>
              ) : this.props.activity.what === "state" ? (
                <img
                  width="20px"
                  height="20px"
                  src={tplaStateUpdate}
                  alt="NA"
                ></img>
              ) : this.props.activity.what === "tpla" ? (
                <img width="20px" height="20px" src={tplaAdded} alt="NA"></img>
              ) : (
                <img
                  width="20px"
                  height="20px"
                  src={tplaFieldUpdate}
                  alt="NA"
                ></img>
              )}
            </div>
          </span>
        </div>
      </div>
    );
  }
}
